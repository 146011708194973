import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import './DetailsAdd.css';
import { useTranslation } from 'react-i18next';
import { ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon, Share as ShareIcon } from '@mui/icons-material';
import { useSpring, animated } from 'react-spring';

const DetailsAdd = () => {
    const { t, i18n } = useTranslation("global");
    const [serviceData, setServiceData] = useState(null);
    const [hospitalData, setHospitalData] = useState(null);
    const getDirection = () => i18n.language === "ar" ? "rtl" : "ltr";
    const location = useLocation();
    const serviceId = new URLSearchParams(location.search).get('id'); // Get service ID from URL query string
    const [openSection, setOpenSection] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const loadData = async () => {
            try {
    
                const baseUrl = process.env.REACT_APP_GET_SERVICE_BY_ID_URL;
                const url = `${baseUrl}?serviceId=${serviceId}`;
    
                const response = await fetch(url);
    
                if (!response.ok) {
                    throw new Error(`Error fetching data: ${response.statusText}`);
                }
    
                const data = await response.json();
    
                if (data.service) {
                    if (typeof data.service.sections === 'string') {
                        try {
                            data.service.sections = JSON.parse(data.service.sections);
                        } catch (e) {
                            console.error("Error parsing sections:", e);
                        }
                    }
                    if (typeof data.service.footer === 'string') {
                        try {
                            data.service.footer = JSON.parse(data.service.footer);
                        } catch (e) {
                            console.error('Error parsing footer:', e);
                        }
                    }
                    if (typeof data.service.special_conditions === 'string') {
                        try {
                            data.service.special_conditions = JSON.parse(data.service.special_conditions);
                        } catch (e) {
                            console.error("Error parsing special_conditions:", e);
                        }
                    }
                    if (typeof data.service.included_services === 'string') {
                        try {
                            data.service.included_services = JSON.parse(data.service.included_services);
                        } catch (e) {
                            console.error("Error parsing included_services:", e);
                        }
                    }
                }
    
                setServiceData(data.service);
                setHospitalData(data.hospital);
            } catch (error) {
                console.error("Error loading data:", error);
            }
        };
    
        loadData();
    }, [serviceId]);
    

    const shareLink = () => {
        const shareData = {
            title: document.title,
            text: "Check out this service:",
            url: window.location.href,
        };

        if (navigator.share) {
            navigator.share(shareData)
                .then(() => console.log('Successful share'))
                .catch((error) => console.log('Error sharing:', error));
        } else {
            alert("Your browser doesn't support the Share API.");
        }
    };

    const toggleSection = (index) => {
        setOpenSection(prev => prev === index ? null : index);
    };

    const renderContent = (content) => {
        if (Array.isArray(content) && content.length > 0) {
            return (
                <ul>
                    {content.map((item, idx) => (
                        <li key={idx}>
                            {item.icon && <i className={`fa ${item.icon}`} aria-hidden="true"></i>} {item.text || item}
                        </li>
                    ))}
                </ul>
            );
        }
        return <p>{content}</p>;
    };

    if (!serviceData) {
        return <p className='whiteloading'>Loading...</p>;
    }

    return (
        <div className='backgroundblue'>
            <div className={`${getDirection()} backgroundwhitelana`} style={{ direction: getDirection() }}>

                {/* Banner section */}
                <div className="bannersollana" style={{
                    backgroundImage: `url(${serviceData.image})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    height: '300px',
                    position: 'relative',
                    textAlign: 'center'
                }}>
                    <button
                        onClick={shareLink}
                        style={{
                            position: 'absolute',
                            top: '10px',
                            right: '10px',
                            backgroundColor: '#0056B3',
                            border: 'none',
                            borderRadius: '50%',
                            color: 'white',
                            padding: '10px',
                            cursor: 'pointer'
                        }}
                    >
                        <ShareIcon style={{ color: 'white' }} />
                    </button>
                </div>

                <div className="detailsContent" dir={getDirection(i18n.language)}>
                    <p className="TitlContFatenlana">{serviceData.title}</p>

                    <p className="summaryText">{serviceData.description}</p>
                    {serviceData.subsummary && <p className='subsummary'>{serviceData.subsummary}</p>}

                    {Array.isArray(serviceData.sections) ? serviceData.sections.map((section, index) => (
                        <div key={index} className="section">
                            <button className="button-collapsess" onClick={() => toggleSection(index)} aria-expanded={openSection === index}>
                                <animated.span style={{ transform: openSection === index ? 'rotate(180deg)' : 'rotate(0deg)', fontSize: 30, color: '#0056B3' }}>
                                    {openSection === index ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                </animated.span>
                                <p className='titlecollaps'>{section.title}</p>
                            </button>
                            {openSection === index && (
                                <animated.div style={{
                                    backgroundColor: '#F4F6FA',
                                    borderBottom: '0.5px solid rgba(0, 0, 0, 0.05)',
                                    transition: 'max-height 0.5s ease-in-out',
                                    overflow: 'hidden'
                                }}>
                                    <div className="sectionContent titlecollapsanouch" dir={getDirection(i18n.language)}>
                                        {renderContent(section.content)}
                                        {section.table && (
                                            <table className="custom-table">
                                                <thead>
                                                    <tr>
                                                        {section.table.headers.map((header, headerIndex) => (
                                                            <th key={headerIndex}>{header}</th>
                                                        ))}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {section.table.data.map((row, rowIndex) => (
                                                        <tr key={rowIndex}>
                                                            {row.map((cell, cellIndex) => (
                                                                <td key={cellIndex} data-label={section.table.headers[cellIndex]}>{cell}</td>
                                                            ))}
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        )}
                                    </div>
                                </animated.div>
                            )}
                        </div>
                    )) : <p>No sections available for this service</p>}

                    {/* Special conditions and included services sections */}
                    {serviceData.special_conditions && (
                        <div className="section">
                            <button className="button-collapsess" onClick={() => toggleSection('special_conditions')} aria-expanded={openSection === 'special_conditions'}>
                                <animated.span style={{ transform: openSection === 'special_conditions' ? 'rotate(180deg)' : 'rotate(0deg)', fontSize: 30, color: '#0056B3' }}>
                                    {openSection === 'special_conditions' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                </animated.span>
                                <p className='titlecollaps'>الشروط الخاصة</p>
                            </button>
                            {openSection === 'special_conditions' && (
                                <animated.div style={{
                                    backgroundColor: '#F4F6FA',
                                    borderBottom: '0.5px solid rgba(0, 0, 0, 0.05)',
                                    transition: 'max-height 0.5s ease-in-out',
                                    overflow: 'hidden'
                                }}>
                                    <div className="sectionContent titlecollapsanouch">
                                        {renderContent(serviceData.special_conditions.content)}
                                    </div>
                                </animated.div>
                            )}
                        </div>
                    )}

                    {serviceData.included_services && (
                        <div className="section">
                            <button className="button-collapsess" onClick={() => toggleSection('included_services')} aria-expanded={openSection === 'included_services'}>
                                <animated.span style={{ transform: openSection === 'included_services' ? 'rotate(180deg)' : 'rotate(0deg)', fontSize: 30, color: '#0056B3' }}>
                                    {openSection === 'included_services' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                </animated.span>
                                <p className='titlecollaps'>الخدمات المشمولة</p>
                            </button>
                            {openSection === 'included_services' && (
                                <animated.div style={{
                                    backgroundColor: '#F4F6FA',
                                    borderBottom: '0.5px solid rgba(0, 0, 0, 0.05)',
                                    transition: 'max-height 0.5s ease-in-out',
                                    overflow: 'hidden'
                                }}>
                                    <div className="sectionContent titlecollapsanouch">
                                        {renderContent(serviceData.included_services.content)}
                                    </div>
                                </animated.div>
                            )}
                        </div>
                    )}

                    <br />
                    <div className='divhelped'>
                        <p className='TitlContFatenlanaBB'>  {serviceData.footer.callToActionTitle}</p>
                        <div className='div2helped'>
                            <p>{serviceData.footer.callToActionDescription}</p>
                        </div>
                    </div>
                    <div className="text-center">
                        <Link to="/HospitalServiceAdd" state={{ id: serviceData.service_id, image: serviceData.image }}>
                            <button className="blueNewTkhile">{serviceData.footer.buttonText}</button>
                        </Link>
                    </div>
                    <br />
                    <br />
                </div>
            </div>
        </div>
    );
};

export default DetailsAdd;

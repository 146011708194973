
import React from 'react';
import { useEffect} from 'react';
import { initializeApp, getApps } from "firebase/app";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import { FaWhatsapp } from "react-icons/fa6";
import Footer from './components/Footer.js';
import Footersection from './components/Footersection.js';
import Navbare from './components/Navbare';
import SignUp from './components/SignUp';
import Home from './components/Home';
import Login from './components/Login';
import Solutions from './components/Solutions';
import Automation from './components/Automation';
import Security from './components/Security';
import Buying_guide from './components/Buying_guide';
import Women from './Women.js';
import Connectivity from './components/Connectivity';
import Support from './components/Support';
import Contact_Us from './components/Contact_Us';
import LatestNews from './components/LatestNews';
import Article from './components/Article';
import Ourclient from './components/Ourclient';
import Details from './Details';
import Services from './Services';
import UserInfoPage from './components/UserInfoPage';
import DetailsAdd from './components/DetailsAdd';
import HospitalServiceAdd from './components/HospitalServiceAdd.js';
import Service_provider from './components/Service_provider.js';
import Pdfuser from './components/Pdfuser.js';
import Data_Service from './components/Data_Service.js';
import Service_Details from './components/Service_Details.js';
import ServiceAdd_Details from './components/ServiceAdd_Details.js';
import MYcontracts from './components/MYcontracts.js';
import PersonalInfoForm from './components/PersonalInfoForm.js';
import SendMessage from './components/SendMessage.js';
import Our_Partner from './components/Our_Partner.js';
import Payment from './components/Payment';
import Privacy from './components/Privacy';
import Authorization_letter from './components/Authorization_letter.js';
import Contract_provisions from './components/Contract_provisions';
import Terms_and_conditions from './components/Terms_and_conditions';
import Responsible from './components/Responsible.js';
import Privacy_policy from './components/Privacy_policy.js';
import My_appointments from './components/My_appointments.js';
import My_contracts from './components/My_contracts.js';
import Establishment_Services_assistance from './components/Establishment_Services_assistance.js';
import Est_Services from './components/Est_Services.js';
import Calendar from './components/Calendar.js';
import '@fortawesome/fontawesome-free/css/all.css';
import { AuthProvider } from "./context/AuthContext";
import VisitorLogger from './components/VisitorLogger';
import Verify from './components/Verify.js';
import VerifyOTP from './components/VerifyOTP.js';
import { useTranslation } from 'react-i18next';
const call = 'https://api.whatsapp.com/send?phone=+966920014308&text=Hello!';

function AppContent() {
  const {  i18n } = useTranslation("global");



  return (
    <BrowserRouter>
      <div className="triabel">{i18n.language === 'ar' ? '(شراء آمن)اهلا بكم في التشغيل التجريبي لمنصة لهاولك الطبية' : '(Secure Purchase) Welcome to the beta launch of the LahaLak Medical Platform.'}</div>
      <Navbare />
      <VisitorLogger />

      <div className="fixed-button">
        <button>
          <a className="text-white" href={call}>
            <FaWhatsapp size={30} />
          </a>
        </button>
      </div>
      <Routes>
        <Route path="/" element={<Services />} />
        <Route path="/women_services" element={<Women />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/login" element={<Login />} />
        <Route path="/contact_us" element={<Contact_Us />} />
        <Route path="/SendMessage" element={<SendMessage />} />
        <Route path="/solutions" element={<Solutions />} />
        <Route path="/automation" element={<Automation />} />
        <Route path="/connectivity" element={<Connectivity />} />
        <Route path="/support" element={<Support />} />
        <Route path="/Latestnews" element={<LatestNews />} />
        <Route path="/article" element={<Article />} />
        <Route path="/details" element={<Details />} />
        <Route path="/ourclient" element={<Ourclient />} />
        <Route path="/user-info" element={<UserInfoPage />} />
        <Route path="/Pdfuser" element={<Pdfuser />} />
        <Route path="/data-service" element={<Data_Service />} />
        <Route path="/Service_providers" element={<Security />} />
        <Route path="/Buying_guide" element={<Buying_guide />} />
        <Route path="/DetailsAdd" element={<DetailsAdd />} />
        <Route path="/HospitalServiceAdd" element={<HospitalServiceAdd />} />
        <Route path="/Service_Details" element={<Service_Details />} />
        <Route path="/home" element={<Home />} />
        <Route path="/My_appointments" element={<My_appointments />} />
        <Route path="/My_contracts" element={<My_contracts />} />
        <Route path="/MYcontracts" element={<MYcontracts />} />
        <Route path="/PersonalInfoForm" element={<PersonalInfoForm />} />
        <Route path="/Payment" element={<Payment />} />
        <Route path="/Contract_provisions" element={<Contract_provisions />} />
        <Route path="/Privacy" element={<Privacy />} />
        <Route path="/ServiceAdd_Details" element={<ServiceAdd_Details />} />
        <Route path="/verify" element={<Verify />} />
        <Route path="/Terms_and_conditions" element={<Terms_and_conditions />} />
        <Route path="/privacy_policy" element={<Privacy_policy />} />
        <Route path="/calendar" element={<Calendar />} />
        <Route path="/user-info" element={<UserInfoPage />} />
        <Route path="/Service_provider" element={<Service_provider />} />
        <Route path="/Job_applications" element={<Women />} />
        <Route path="/verify-otp" element={<VerifyOTP />} />
        <Route path="/Our_Partner" element={<Our_Partner />} />
        <Route path="/Establishment_Services_assistance" element={<Establishment_Services_assistance />} />
        <Route path="/Est_Services" element={<Est_Services />} />
        <Route path="/ServiceAdd_Details" element={<ServiceAdd_Details />} />
        <Route path="/Footersection" element={<Footersection />} />
        <Route path="/Responsible" element={<Responsible />} />
        <Route path="/Authorization_letter" element={<Authorization_letter />} />
        <Route path="*" element={<div><h1>404 Page not found.</h1></div>} />
      </Routes>
      <br />

      <Footer />
    </BrowserRouter>
  );
}

function App() {
  useEffect(() => {
    if (!getApps().length) {
      const app = initializeApp({
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
        projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_FIREBASE_APP_ID,
        measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
      });
    }
  }, []);
  return (
    <AuthProvider>
      <AppContent />
    </AuthProvider>
  );
}

export default App;

















import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import icon2Img from '../components/images/se.png';


import './Data_Service.css';



function Data_Service() {
    const { t, i18n } = useTranslation("global");
    const getDirection = () => {
        return i18n.language === "ar" ? "rtl" : "ltr";
    };

    const location = useLocation();
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 768px)'
    });
    const { sevicename,
        hospitalbranche,
        id,
        name,
        phone,
        total,
        cartItems,
        reference_number,
        nationality,
        Service,
        date,
        hospital,
        roomType,
        email,
        specialConditions,
        bookingDetails,
        includedServices,
        serviceDetails = [],
    } = location.state || {
        id: "No ID passed",
        name: "No name passed",
        phone: "No phone number",
        total: 0,
        cartItems: [],
        reference_number: "No reference number",
        date: ""
    };

    const [agreed, setAgreed] = useState(false);


    const [types, setTypes] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [amount, setAmount] = useState(total || 0);
    const [showNewButton, setShowNewButton] = useState(false);
    useEffect(() => {
        if (showNewButton) {
            console.log("New button should be displayed now.");
        }
    }, [showNewButton]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const language = i18n.language === "ar" ? "Ar" : "En";

                const dataModule = await import(`../translation/${language}/Call.json`);
                const calls = dataModule.default;

                const typeData = calls.filter(call => call.id === String(id));

                if (typeData.length === 0) {
                    throw new Error('No matching data found for the provided ID');
                }

                setTypes(typeData[0]);



                setError(null);
            } catch (err) {
                setError(`Error: ${err.message}`);

            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [id, i18n.language]);

    useEffect(() => {
    }, [location.state]);

    const parsedTotal = typeof total === 'string' ? Number(total) : total;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    const handlePaymentNow = async (e) => {
        e.preventDefault();
        if (!amount || !email || !phone || !reference_number) {
            setError(t("Payment.missingInfo"));
            return;
        }

        const formData = new FormData();
        formData.append('amount', amount);
        formData.append('payment_option', 'NORMAL'); // Fixed to NORMAL
        formData.append('email', email);
        formData.append('phone_number', phone);
        formData.append('reference_number', reference_number);

        try {
            const processPaymentUrl = process.env.REACT_APP_PROCESS_PAYMENT_URL;

            const response = await fetch(processPaymentUrl, {
                method: 'POST',
                body: formData,
            });

            const result = await response.text();
            document.open();
            document.write(result);
            document.close();
        } catch (error) {
            setError(t("Payment.errorProcessing"));
        }
    };


    const currentDate = new Date();
    const optionsGregorian = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
    const datePlus24Hours = new Date(currentDate.getTime() + 48 * 60 * 60 * 1000);
    const formattedGregorianDatePlus24 = new Intl.DateTimeFormat("en-US", optionsGregorian).format(datePlus24Hours);
    const formattedDatePlus24 = `${formattedGregorianDatePlus24}`;




    const accommodationGrade = [
        { name: t("pdfuser.accommodation1"), showIcon: false },
        { name: t("pdfuser.accommodation2"), showIcon: false },
        { name: t("pdfuser.accommodation3"), showIcon: false },
        { name: t("pdfuser.accommodation4"), showIcon: false }
    ];
    const updateAccommodationGrade = (roomType) => {
        switch (roomType) {
            case 'fcr':
                accommodationGrade[0].showIcon = true;
                break;
            case 'rr':
                accommodationGrade[1].showIcon = true;
                break;
            case 'mr':
                accommodationGrade[2].showIcon = true;
                break;
            case 'pr':
                accommodationGrade[3].showIcon = true;
                break;
            default:
                break;
        }
    };
    updateAccommodationGrade(roomType);

    const servicePrice = parsedTotal * 0.75;
    const contractPrice = parsedTotal * 0.25;
    const vatServicePrice = nationality === "Saudi" ? 0 : servicePrice * 0.15;
    const vatContractPrice = contractPrice * 0.15;
    const vatContractPriceT = vatContractPrice + contractPrice;
    const totalWithVat = parsedTotal + vatServicePrice + vatContractPrice;
    const generalConditions = [
        t("Details.sub32"),
        t("Details.sub33"),
        t("Details.sub34"),
        t("Details.sub37")
    ];

    return (
        <div>
            <div className="invoice" id="invoice"  >
                <header className="invoice-header" style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    borderBottom: '2px solid #000'
                }}>

                    <div style={{ textAlign: 'left' }}>
                        <p className="headerpdfT">LahaLak medical platform</p>
                        <p className="headerpdf">Maroof no :214657</p>
                        <p className="headerpdf">T.M 1443017765</p>
                        <p className="headerpdf">C.R. 4030402711</p>
                        <p className="headerpdf">Tax reg 300095977700003 </p>

                    </div>
                    <img
                        src="images/loba2.png"
                        alt="Description of the image"
                        style={{ height: 'auto', width: '100px' }}
                    />
                    <div style={{ textAlign: 'right' }}>
                        <p className="headerpdfT">منصة لها ولك الطبية </p>
                        <p className="headerpdf">رقـــــــــم معروف 214657</p>
                        <p className="headerpdf">عـــــلامة تجارية 1443017765</p>
                        <p className="headerpdf"> السجل التجاري 4030402711</p>
                        <p className="headerpdf">الرقم الضريبي 300095977700003</p>
                    </div>
                </header>
                <div className="user-details" id="pdf-content" dir={getDirection(i18n.language)}>
                    <h1>{t("pdfuser.Quotation")}</h1>
                    <div className="infoBlock" dir={getDirection(i18n.language)}>
                        <div className="infoItem">
                            <p className="label">{t("pdfuser.Reference")}</p>
                            <p className="value">{reference_number}</p>
                        </div>
                        <div className="infoItem">
                            <p className="label">{t("pdfuser.Date")}</p>
                            <p className="value">{date}</p>
                        </div>
                    </div>
                    <br />
                    <div className=' aligne'>
                        <p className='redkhatb' style={{ color: 'red' }}>
                            {t("pdfuser.deadLine")}
                            {/* <span className='sostext'>{`${t("pdfuser.date")} ${formattedDatePlus24}`}</span> */}
                        </p>
                    </div>

                    <br />


                    <div className='BluedivHC' dir={getDirection(i18n.language)}>
                        <p className='titlecsollapseeWak'>{t("pdfuser.data")}</p>
                    </div>
                    <div className=" why" dir={getDirection(i18n.language)}>
                        {isDesktopOrLaptop ? (
                            <div className='mobiletl' dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>
                                <div className='det_gr'>
                                    <div className='grid-cellule'>
                                        <p  style={{ display: 'inline' }}>{t("pdfuser.name")}: </p>
                                        <span className='sostext' style={{ display: 'inline' }}>{name}</span>
                                    </div>
                                    <div className='grid-cellule'>
                                        <p  style={{ display: 'inline' }}>{t("pdfuser.nationality")}: </p>
                                        <span className='sostext' style={{ display: 'inline' }}> {t(`Obstetrics_and_Gynecology.${nationality}`)}</span>
                                    </div>
                                    <div className='grid-cellule'>
                                        <p  style={{ display: 'inline' }}>{t("pdfuser.phone")}: </p>
                                        <span className='sostext' style={{ display: 'inline' }}>  {phone}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className='desktoptl' dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>
                                <div className='det_gr'>
                                    <div className='grid-cellule'>
                                        <p>{t("pdfuser.name")}:
                                            <span className='sostext'> {name}</span>
                                        </p>
                                    </div>
                                    <div className='grid-cellule'>
                                        <p >
                                            {t("pdfuser.nationality")}:
                                            <span className='sostext'>
                                                {t(`Obstetrics_and_Gynecology.${nationality}`)}
                                            </span>
                                        </p>
                                    </div>
                                    <div className='grid-cellule'>
                                        <p >
                                            {t("pdfuser.phone")}:
                                            <span className='sostext'> {phone} </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>


                    <div className='BluedivHC' style={{ direction: getDirection() }}>
                        <p className='titlecsollapseeWak'>{t("pdfuser.dataService")}</p>
                    </div>
                    <div className="why" dir={getDirection(i18n.language)}>
                        {isDesktopOrLaptop ? (
                            <div className='mobiletl' dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>
                                <div className='det_gr'>
                                    <div className='grid-cellule'>
                                        <p  style={{ display: 'inline' }}>{t("pdfuser.hospitalName")}: </p>
                                        <span className='sostext' style={{ display: 'inline' }}>{hospital}</span>
                                    </div>
                                    <div className='grid-cellule '>
                                        <p  style={{ display: 'inline' }}>
                                            {t("pdfuser.Branch")}:
                                        </p>
                                        <span className='sostext' style={{ display: 'inline' }}>
                                            {hospitalbranche}
                                        </span>
                                    </div>
                                    <div className='grid-cellule'>
                                        <p  style={{ display: 'inline' }}>{t("pdfuser.ServiceName")}: </p>
                                        <span className='sostext' style={{ display: 'inline' }}>{Service}</span>
                                    </div>
                                </div>


                            </div>
                        )
                            : (
                                // mobile
                                <div
                                    className="financial-info-container"
                                    style={{
                                        '--direction': i18n.language === 'ar' ? 'rtl' : 'ltr',
                                    }}
                                >
                                    <div className='det_gr'>

                                        <div className='grid-cellule'>
                                            <p  style={{ display: 'inline' }}>{t("pdfuser.hospitalName")}: </p>
                                            <span className='sostext' style={{ display: 'inline' }}>{hospitalbranche}</span>
                                        </div>

                                        <div className='grid-cellule'>
                                            <p  style={{ display: 'inline' }}>
                                                {t("pdfuser.Branch")}:
                                            </p>
                                            <span className='sostext' style={{ display: 'inline' }}>
                                                {hospitalbranche}
                                            </span>
                                        </div>

                                        <div className='grid-cellule'>
                                            <p  style={{ display: 'inline' }}>{t("pdfuser.ServiceName")}: </p>
                                            <span className='sostext' style={{ display: 'inline' }}>{Service}</span>
                                        </div>

                                    </div>

                                </div>

                            )}
                    </div>

                </div>


                <div className='khadametOukhra Wa 5damet mudafe '>


                    <br />

                    <div className="fetched-data">
                        <div dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>
                            <div className='titlecollapseeB'>


                                <div className='BluedivHC'>
                                    <p className='titlecsollapseeWak'>{t("Details.includedServices")}</p>
                                </div>
                                <br />
                            </div>
                            <ul>
                                {includedServices && includedServices.map((service, index) => (
                                    <li key={index} className="totire">
                                        <img src={icon2Img} alt="Icon" />
                                        <span>{typeof service === 'object' ? service.description1 : service}</span>
                                    </li>
                                ))}
                            </ul>

                        </div>
                    </div>
                    <br />

                    <div className="fetched-data ">
                        <div dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>
                            <div className='titlecollapseeB'>

                                <div className='BluedivHC'>
                                    <p className='titlecsollapseeWak'>{t("Details.bookingDetails")}</p>
                                </div>
                                <br />
                            </div>
                            <ul>
                                {bookingDetails && bookingDetails.map((detail, index) => (
                                    <li key={index} className="totire">
                                        <img src={icon2Img} alt="Icon" />
                                        <span>{typeof detail === 'object' ? detail.description1 : detail}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <br />

                    <div className="fetched-data">
                        <div dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>
                            <div className='titlecollapseeB'>
                                <div className='BluedivHC'>
                                    <p className='titlecsollapseeWak'>{t("Details.specialConditions")}</p>
                                </div>

                                <br />
                            </div>
                            <ul>
                                {specialConditions && specialConditions.map((condition, index) => (
                                    <li key={index} className="totire">
                                        <img src={icon2Img} alt="Icon" />
                                        <span>{typeof condition === 'object' ? condition.description1 : condition}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <br />

                    <div className="fetched-data ">
                        <div dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>
                            <div className='titlecollapseeB'>


                                <div className='BluedivHC'>
                                    <p className='titlecsollapseeWak'>{t("Details.generalConditions")}</p>
                                </div>
                                <br />
                            </div>

                            <div className={`${i18n.language === 'ar' ? 'rtl' : 'ltr'} titlecollapseeB`} dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>
                                <ul style={{ padding: 0 }}>
                                    {generalConditions.map((condition, index) => (
                                        <li key={index} className="safamak">
                                            <span>{condition}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="why" dir={getDirection(i18n.language)}>
                    {isDesktopOrLaptop ? (
                        // desktop
                        <div className='mobiletl' dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>

                            <div
                                className="financial-data"
                                style={{
                                    '--direction': i18n.language === 'ar' ? 'rtl' : 'ltr'
                                }}
                            >
                                <table>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>الخدمة</th>
                                            <th>السعر بدون الضريبة</th>
                                            <th>السعر مع الضريبة</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>{Service}</td>

                                            {/* <td>
                                                    {servicePrice.toFixed(2)} &nbsp; {t("Services.Pur6")}  
                                                    {parsedTotal.toFixed(2)} &nbsp; {t("Services.Pur6")}
                                                </td> */}
                                            <td>{servicePrice.toFixed(2)} &nbsp; {t("Services.Pur6")}</td>

                                            <td>
                                                {nationality === "Saudi" ? (
                                                    <span>
                                                        {servicePrice.toFixed(2)} &nbsp; {t("Services.Pur6")}
                                                    </span>
                                                ) : (
                                                    <span>
                                                        {(servicePrice + vatServicePrice).toFixed(2)} &nbsp; {t("Services.Pur6")}
                                                    </span>
                                                )}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>2</td>
                                            <td>الرسوم الإدارية</td>
                                            <td>{contractPrice.toFixed(2)} &nbsp; {t("Services.Pur6")}</td>
                                            <td> {vatContractPriceT.toFixed(2)}  &nbsp; {t("Services.Pur6")}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="3" className="total-label">المجموع</td>
                                            <td className="total-value">{totalWithVat.toFixed(2)} &nbsp; {t("Services.Pur6")}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>





                        </div>
                    )
                        : (
                            // mobile
                            <div
                                className="financial-info-container"
                                style={{
                                    '--direction': i18n.language === 'ar' ? 'rtl' : 'ltr',
                                }}
                            >

                                <div className="financial-table-wrapper">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>الخدمة</th>
                                                <th>السعر بدون الضريبة</th>
                                                <th>السعر مع الضريبة</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>{Service}</td>
                                                <td>{servicePrice.toFixed(2)} &nbsp; {t("Services.Pur6")}</td>
                                                <td>
                                                    <span>
                                                        {nationality === "Saudi"
                                                            ? servicePrice.toFixed(2)
                                                            : (servicePrice + vatServicePrice).toFixed(2)}
                                                        &nbsp; {t("Services.Pur6")}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>الرسوم الإدارية</td>
                                                <td>{contractPrice.toFixed(2)} &nbsp; {t("Services.Pur6")}</td>
                                                <td>{vatContractPriceT.toFixed(2)} &nbsp; {t("Services.Pur6")}</td>
                                            </tr>
                                            <tr>
                                                <td colSpan="3" className="total-label">المجموع</td>
                                                <td className="total-value">{totalWithVat.toFixed(2)} &nbsp; {t("Services.Pur6")}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        )}
                </div>





                <div>
                    {!agreed ? (
                        <div
                            className="agreement-section"
                            style={{
                                textAlign: 'center',
                                marginTop: '20px',
                                backgroundColor: '#f9f9f9',
                                padding: '15px',
                                borderRadius: '8px',
                                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                            }}
                        >
                            <label
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    gap: '12px',
                                    fontSize: '16px',
                                    fontWeight: '500',
                                    color: '#333',
                                }}
                            >

                                <span style={{ margin: 0 }}>
                                    {t("pdfuser.agreeToTerms")}{" "}
                                    <a
                                        href="https://lahalak.sa/Terms_and_conditions"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{
                                            color: '#0056b3',
                                            textDecoration: 'none',
                                            fontWeight: '600',
                                            cursor: 'pointer',
                                        }}
                                        onMouseOver={(e) => (e.target.style.textDecoration = 'underline')}
                                        onMouseOut={(e) => (e.target.style.textDecoration = 'none')}
                                    >
                                        {t("pdfuser.termsAndConditions")}
                                    </a>
                                </span>

                                <span style={{ margin: 0 }}>
                                    {t("pdfuser.agreeToTermsT")}{" "}
                                    <a
                                        href="https://lahalak.sa/privacy_policy"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{
                                            color: '#0056b3',
                                            textDecoration: 'none',
                                            fontWeight: '600',
                                            cursor: 'pointer',
                                        }}
                                        onMouseOver={(e) => (e.target.style.textDecoration = 'underline')}
                                        onMouseOut={(e) => (e.target.style.textDecoration = 'none')}
                                    >
                                        {t("pdfuser.privacy_policy")}
                                    </a>
                                </span>
                                <input
                                    type="checkbox"
                                    checked={agreed}
                                    onChange={() => {
                                        setAgreed(!agreed);
                                    }}
                                    style={{
                                        width: '20px',
                                        height: '20px',
                                        cursor: 'pointer',
                                        accentColor: '#0056b3',
                                        border: '1px solid #ccc',
                                        borderRadius: '4px',
                                    }}
                                />
                            </label>
                        </div>

                    ) : (
                        <button
                            onClick={() => {
                                // setLoadingPdf(true);
                                // generatePDF();
                            }}
                            className="button-downloadF buttonwhite"
                        >

                            <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <div className='containerhkina'>
                                    <form onSubmit={handlePaymentNow} className="payment-form">

                                        <div className="form-group">
                                            <button type="submit" className="button-download blueNewTkhile">
                                                {t("Payment.payNow")}
                                            </button>
                                        </div>
                                    </form>

                                </div>
                            </span>
                        </button>
                    )}
                </div>
            </div>

        </div>
    );
}

export default Data_Service;

import React, { useEffect, useState, useRef } from "react";
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { useAuthContext } from '../context/AuthContext';
import { useTranslation } from 'react-i18next';

import 'flag-icons/css/flag-icons.min.css'; 
import './Navbare.css'; 
import { useNavigate } from 'react-router-dom';

const Navbar = () => { 
  const { user } = useAuthContext();
  const { t, i18n } = useTranslation("global");
  const [nav, setNav] = useState(false);
  const navigate = useNavigate();

  const [isConverted, setIsConverted] = useState(i18n.language === 'ar');
  const navRef = useRef();
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredServices1, setFilteredServices1] = useState([]);
  const [debouncedQuery, setDebouncedQuery] = useState(searchQuery);
  const [additionalServices1, setAdditionalServices1] = useState([]);

  const handleNav = () => setNav(!nav);
  const closeNav = () => setNav(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const dropdownRef = useRef(null); 

  const handleLanguageChange = (languageCode) => {
    i18n.changeLanguage(languageCode)
      .then(() => {
        console.log(`Language changed to ${languageCode}`);
        setIsConverted(languageCode === 'ar');
      })
      .catch((error) => {
        console.error('Error changing language:', error);
      });

    closeNav();
  };
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedQuery(searchQuery);
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [searchQuery]);
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (nav && navRef.current && !navRef.current.contains(e.target)) {
        setNav(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [nav]);
  useEffect(() => {
    const loadData1 = async () => {
      try {
        const response1 = await fetch(process.env.REACT_APP_API_URL);
        const data1 = await response1.json();

        if (Array.isArray(data1)) {
          setAdditionalServices1(data1);
        } else {
          console.error("Data1 is not in the expected format (array)");
          setAdditionalServices1([]);
        }
      } catch (error) {
        console.error("Error loading additional services data:", error);
      }
    };

    loadData1();
  }, []);
  const getDirection = () => (i18n.language === "ar" ? "rtl" : "ltr");
  const LanguageDropdown = () => {
    const [isOpen, setIsOpen] = useState(false);
    const languages = [
      { code: 'ar',name: 'العربية',   flagClass: 'fi fi-sa'   }  
,
      { code: 'en',  name: 'English' , flagClass: 'fi fi-gb'  }
    ];

    const selectedLanguage = languages.find(lang => lang.code === i18n.language);
    const oppositeLanguage = languages.find((lang) => lang.code !== i18n.language);

    const handleLanguageSelect = (language) => {
      handleLanguageChange(language.code);
      setIsOpen(false);
    };

    return (
      <div className="dropdown">
        <button className="dropdown-btn" onClick={() => setIsOpen(!isOpen)}>
          <span className={oppositeLanguage.flagClass} style={{ marginRight: '8px' }}></span>
          {oppositeLanguage.name}
        </button>

        {isOpen && (
          <ul className="dropdown-menu">
            {languages.map(language => (
              <li
                key={language.code}
                className="dropdownitem"
                onClick={() => handleLanguageSelect(language)}
              >
                <span className={language.flagClass} style={{ marginRight: '8px' }}></span> {language.name}
              </li>
            ))}
          </ul>
        )}
      </div>
    );
 
  };
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setIsDropdownVisible(true); // Show the dropdown when typing
  };
  return (
    <div ref={navRef} className='navbare flex justify-between items-center max-w-[100%] mx-auto px-4 text-black relative z-10' style={{ direction: getDirection() }}>
    
      <div style={{ display: 'flex' }}>
      <h1 className='text-3xl font-bold text-[#00df9a]'>
          <Link to="/" style={{ display: 'flex', alignItems: 'center' }}>
            <img
              src="images/loba2.png"
              alt="Description of the image"
              className="navbar-logo"
            />
          </Link>
        </h1>
      </div>

      <ul className='hidden md:flex' style={{ fontSize: '18px' }}>
      <li className='p-3'>
          <Link to={user ? "/Home" : "/signup"} style={{ color: 'white' }} onClick={closeNav}>
            <button className={`buttontr ${isConverted ? 'blackButton' : ''}`}>
              <p className="redcoffe">{user ? t("Navbar.login2") : t("Navbar.login1")}</p>
            </button>
          </Link>
        </li>
        <li className='p-3'>
          <LanguageDropdown />
        </li>
        
      </ul>

      <div onClick={handleNav} className='block md:hidden'>
        {nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
      </div>
      <ul className={nav ? 'fixed left-0 top-0 w-[60%] h-full border-r border-r-gray-900 bg-[#072C7B] ease-in-out duration-500 z-20' : 'ease-in-out duration-500 fixed left-[-100%]'} style={{ fontSize: '16px' }}>
        <br/>
        <br/>
        <br/>
        <div className="centre logo-container">
          <Link to="/" onClick={closeNav}>
            <img
              src="images/loba.png"
              alt="Descripttttion"
              className="navbar-logo"
              style={{ height: 'auto', width: '50px' }}
            />
          </Link>
        </div>
        <li className='p-3'>
          <LanguageDropdown />
        </li>

        <li className='p-4'>
          <button className={`buttontr ${isConverted ? 'blackButton' : ''}`}>
            <Link to={user ? "/Home" : "/signup"} style={{ color: 'white' }} onClick={closeNav}>
               {user ? t("Navbar.login2") : t("Navbar.login1")}
            </Link>
          </button>
        </li>
      </ul>
    </div>
  );
};

export default Navbar;


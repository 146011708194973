import React, { useState, useEffect } from 'react';
import { useAuthContext } from "../context/AuthContext";
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import './MYcontracts.css';

const MyContracts = () => {
  const { user } = useAuthContext();
  const { t, i18n } = useTranslation("global");
  const [contracts, setContracts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchContracts = async () => {
      if (user?.phone) {
        console.log("User phone detected:", user.phone); // Debug user phone
        try {
          const getContractsUrl = process.env.REACT_APP_GET_CONTRACTS_URL;
          console.log("Fetching contracts from URL:", getContractsUrl); // Debug API endpoint

          const response = await fetch(getContractsUrl, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ phone: user.phone }),
          });

          const data = await response.json();
          console.log("Response data:", data); // Debug full response

          if (data.status === 'success') {
            const contractsList = data.contracts.map((contract, index) => {
              console.log(`Processing contract:`, contract); // Debug each contract
              return {
                ...contract,
                className: `MyContracts${index + 1}`,
                timestamp: new Date(contract.timestamp).toLocaleDateString() || 'N/A',
              };
            });

            setContracts(contractsList); // Directly set contracts
          } else {
            console.error('Error fetching contracts:', data.message); // Debug error message
          }
        } catch (error) {
          console.error("Error fetching contracts:", error); // Debug fetch error
        } finally {
          setLoading(false);
        }
      } else {
        console.warn("No phone number available in user object"); // Debug missing phone
      }
    };

    fetchContracts();
  }, [user?.phone]);

  const copyToClipboard = (referenceNumber) => {
    console.log("Copying reference number to clipboard:", referenceNumber); // Debug copy action
    navigator.clipboard.writeText(referenceNumber);
  };

  if (loading) {
    console.log("Loading contracts..."); // Debug loading state
    return (
      <div className="loading-container">
        <div className="spinner"></div>
        <p style={{ color: '#ffffff' }}>{t("MyContracts.loading")}</p>
      </div>
    );
  }

  const getDirection = () => {
    const direction = i18n.language === "ar" ? "rtl" : "ltr";
    console.log("Language direction:", direction); // Debug text direction
    return direction;
  };

  return (
    <div className="my-contracts-container" dir={getDirection()}>
      <h1 className="my-contracts-title">{t('Home.quotes')}</h1>
      {contracts.length === 0 ? (
        <p className="no-contracts-message">{t("MyContracts.noContracts")}</p>
      ) : (
        <ul className="contracts-list">
          {contracts.map((contract, index) => (
            <li key={index} className={`contract-item ${contract.className}`}>
              <div className="contract-details">
                {/* <p className="contract-ref">
                  <strong>{t("MyContracts.Reference")}:</strong> {contract.referenceNumber}
                  <button
                    className="copy-button"
                    onClick={() => copyToClipboard(contract.referenceNumber)}
                  >
                    <FontAwesomeIcon icon={faCopy} />
                  </button>
                </p> */}
                <p className="contract-date">
                  <strong>{t("MyContracts.date")}:</strong> {contract.timestamp}
                </p>
              
              </div>
              <a
                href={`https://lahalak.sa/Authorization_letter?referenceNumber=${contract.referenceNumber}`}
                target="_blank"
                rel="noopener noreferrer"
                className="download-link"
              >
                {t("MyContracts.viewDetails")}
              </a>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
 
export default MyContracts;

import React from 'react';
import './Gorfe.css';
import { useTranslation } from 'react-i18next';

import comma from './images/green/comma.png';
import abasher from './images/green/abasher.png';
import BNGL from './images/green/BNGL.png';
import shield from './images/green/shield.png';
import location from './images/green/location.png';
import free from './images/green/free.png';
import Document from './images/green/Document.png';

const Gorfe = () => {
    const { t, i18n } = useTranslation("global");

    const getDirection = () => {
        return i18n.language === "ar" ? "rtl" : "ltr";
    };

    return (
        <div className="treatment-container" style={{ direction: getDirection() }}>
            <div className="header-section">
                <div className="talib14">
                    <div className="talib0">
                        <div className="column image-column">
                            <img src={Document} alt="Document icon" className="talib2image" />
                        </div>
                        <div className="column text-column">
                            <p className='talib1'>
                                {t("Gorfe.Headsoltitle1")}
                            </p>
                            <p className='talib2'>
                                {t("Gorfe.DocumentDescription")}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="offers-section">
                <br />
                <div className='wardoana'>
                    <p className='wardoanatxt'>{t("Gorfe.OffersTitle")}</p>
                </div>
                <br />
                <div className="offer-items">
                    <div className="offer-item">
                        <img src={shield} alt="Shield icon" className="offer-icon" />
                        <p>{t("Gorfe.Offer1")}</p>
                    </div>

                    <div className="offer-item">
                        <img src={free} alt="Free icon" className="offer-icon" />
                        <p>{t("Gorfe.Offer2")}</p>
                    </div>

                    <div className="offer-item">
                        <img src={BNGL} alt="BNGL icon" className="offer-icon" />
                        <p>{t("Gorfe.Offer3")}</p>
                    </div>

                    <div className="offer-item">
                        <img src={location} alt="Location icon" className="offer-icon" />
                        <p>{t("Gorfe.Offer4")}</p>
                    </div>

                    <div className="offer-item">
                        <img src={abasher} alt="Abasher icon" className="offer-icon" />
                        <p>{t("Gorfe.Offer5")}</p>
                    </div>

                    <div className="offer-item">
                        <img src={comma} alt="Comma icon" className="offer-icon" />
                        <p>{t("Gorfe.Offer6")}</p>
                    </div>
                </div>
            </div>
            <br />
            <br />

        </div>
    );
};

export default Gorfe;

import React, { useState } from 'react';
import { useAuthContext } from "../context/AuthContext";
import { useTranslation } from 'react-i18next';
import './PersonalInfoForm.css';

const PersonalInfoForm = () => {
    const { user } = useAuthContext();
    const { t, i18n } = useTranslation("global");
    const getDirection = () => {
        return i18n.language === "ar" ? "rtl" : "ltr";
    };
    const [formData, setFormData] = useState({
        dob: '',
        gender: '',
        city: '',
        nationality: '',
        phoneNumber: '',
        email: user.email, // Use user email directly
        bloodType: '',
        chronicDiseases: [],
        smoker: '',
        interests: []
    });
    const [isEditing, setIsEditing] = useState(true);
    const [password, setPassword] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);



    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        if (type === 'checkbox') {
            setFormData((prevState) => ({
                ...prevState,
                [name]: checked
                    ? [...prevState[name], value]
                    : prevState[name].filter((v) => v !== value),
            }));
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        window.scrollTo(0, 0); // Scroll to the top of the page
        setIsModalOpen(true);
    };

    const handlePasswordSubmit = async () => {
        try {
            const verifyPasswordUrl = process.env.REACT_APP_VERIFY_PASSWORD_URL;
    
            const response = await fetch(verifyPasswordUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: user.email,
                    password: password,
                }),
            });
    
            const result = await response.json();
    
            if (result.status === 'success') {
                // Save user data after successful password verification
                await saveUserData();
            } else {
                alert(result.message || 'Incorrect password. Please try again.');
            }
        } catch (error) {
            console.error('Error verifying password:', error);
            alert('An error occurred. Please try again.');
        }
    };
    
    

    const saveUserData = async () => {
        try {
            const response = await fetch('/path/to/your/php/save_user_data.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            const result = await response.json();

            if (result.success) {
                alert(t('PersonalInfoForm.submit'));
                setFormData({
                    dob: '',
                    gender: '',
                    city: '',
                    nationality: '',
                    phoneNumber: '',
                    email: user.email,
                    bloodType: '',
                    chronicDiseases: [],
                    smoker: '',
                    interests: []
                });
                setIsModalOpen(false);
                setPassword('');
            } else {
                alert('Failed to save user data. Please try again.');
            }
        } catch (error) {
            console.error('Error saving user data:', error);
            alert('An error occurred. Please try again.');
        }
    };

    return (
        <div>
            <form onSubmit={handleSubmit} className='PersonalInfoF' style={{ direction: getDirection() }}>
                <div className="PersonalInfoForm">
                    <h2 className="PersonalInfoForm1">{t('PersonalInfoForm.personalInformation')}</h2>
                    <div className="form-group">
                        <label className="sostitel">{t('PersonalInfoForm.age')}</label>
                        <input
                            disabled={!isEditing}
                            type="date"
                            name="dob"
                            value={formData.dob}
                            onChange={handleChange}
                            className="PersonalInfoForm3"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label className="sostitel">{t('PersonalInfoForm.gender')}</label>
                        <select
                            disabled={!isEditing}
                            id="gender"
                            value={formData.gender}
                            onChange={(e) => setFormData({ ...formData, gender: e.target.value })}
                            className="PersonalInfoForm3"
                            required
                        >
                            <option value="">{t("UserInfoPage.gender")}</option>
                            <option value="male">{t("UserInfoPage.male")}</option>
                            <option value="female">{t("UserInfoPage.female")}</option>
                            <option value="other">{t("UserInfoPage.other")}</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label className="sostitel">{t('PersonalInfoForm.city')}</label>
                        <select
                            disabled={!isEditing}
                            id="city"
                            value={formData.city}
                            onChange={(e) => setFormData({ ...formData, city: e.target.value })}
                            className="PersonalInfoForm3"
                            required
                        >
                            <option value="">{t("PersonalInfoForm.enterCity")}</option>
                            {Object.keys(t('PersonalInfoForm.cities', { returnObjects: true })).map(city => (
                                <option key={city} value={city}>
                                    {i18n.language === 'ar' ? t(`PersonalInfoForm.cities.${city}`) : city}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="form-group">
                        <label className="sostitel">{t('PersonalInfoForm.nationality')}</label>
                        <select
                            disabled={!isEditing}
                            id="nationality"
                            value={formData.nationality}
                            onChange={(e) => setFormData({ ...formData, nationality: e.target.value })}
                            required
                            className="PersonalInfoForm3"
                        >
                            <option value="">{t("UserInfoPage.nationality")}</option>
                            <option value="Saudi">{t("UserInfoPage.value1")}</option>
                            <option value="Non-Saudi">{t("UserInfoPage.value2")}</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label className="sostitel">{t('PersonalInfoForm.phoneNumber')}</label>
                        <input
                            type="text"
                            name="phoneNumber"
                            value={formData.phoneNumber}
                            onChange={handleChange}
                            className="PersonalInfoForm3"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label className="sostitel">{t('PersonalInfoForm.email')}</label>
                        <input
                            type="email"
                            name="email"
                            value={user.email}
                            readOnly
                            className="PersonalInfoForm3"
                            required
                        />
                    </div>

                    <h2 className="PersonalInfoForm1">{t('PersonalInfoForm.medicalFile')}</h2>
                    <div className="ker1">
                        <label className="ker2">{t('PersonalInfoForm.bloodType')}</label>
                        <div className="ker3 ker-column">
                            <div className="ker-row">
                                <label className="ker-item">
                                    A+
                                    <input
                                        type="radio"
                                        name="bloodType"
                                        value="A+"
                                        checked={formData.bloodType === 'A+'}
                                        onChange={handleChange}
                                        className="ker6"
                                    />
                                </label>
                                <label className="ker-item">
                                    A-
                                    <input
                                        type="radio"
                                        name="bloodType"
                                        value="A-"
                                        checked={formData.bloodType === 'A-'}
                                        onChange={handleChange}
                                        className="ker6"
                                    />
                                </label>
                                <label className="ker-item">
                                    B+
                                    <input
                                        type="radio"
                                        name="bloodType"
                                        value="B+"
                                        checked={formData.bloodType === 'B+'}
                                        onChange={handleChange}
                                        className="ker6"
                                    />
                                </label>
                                <label className="ker-item">
                                    B-
                                    <input
                                        type="radio"
                                        name="bloodType"
                                        value="B-"
                                        checked={formData.bloodType === 'B-'}
                                        onChange={handleChange}
                                        className="ker6"
                                    />
                                </label>
                            </div>
                            <div className="ker-row">
                                <label className="ker-item">
                                    AB+
                                    <input
                                        type="radio"
                                        name="bloodType"
                                        value="AB+"
                                        checked={formData.bloodType === 'AB+'}
                                        onChange={handleChange}
                                        className="ker6"
                                    />
                                </label>
                                <label className="ker-item">
                                    AB-
                                    <input
                                        type="radio"
                                        name="bloodType"
                                        value="AB-"
                                        checked={formData.bloodType === 'AB-'}
                                        onChange={handleChange}
                                        className="ker6"
                                    />
                                </label>
                                <label className="ker-item">
                                    O+
                                    <input
                                        type="radio"
                                        name="bloodType"
                                        value="O+"
                                        checked={formData.bloodType === 'O+'}
                                        onChange={handleChange}
                                        className="ker6"
                                    />
                                </label>
                                <label className="ker-item">
                                    O-
                                    <input
                                        type="radio"
                                        name="bloodType"
                                        value="O-"
                                        checked={formData.bloodType === 'O-'}
                                        onChange={handleChange}
                                        className="ker6"
                                    />
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className="ker1">
                        <label className="ker2">{t('PersonalInfoForm.chronicDiseases')}</label>
                        <div className="ker4">
                            <label className="ker5">
                                {t('PersonalInfoForm.diabetes')}
                                <input
                                    type="checkbox"
                                    name="chronicDiseases"
                                    value="سكري"
                                    checked={formData.chronicDiseases.includes('سكري')}
                                    onChange={handleChange}
                                    className="ker6"
                                />
                            </label>
                            <label className="ker5">
                                {t('PersonalInfoForm.hypertension')}
                                <input
                                    type="checkbox"
                                    name="chronicDiseases"
                                    value="ضغط"
                                    checked={formData.chronicDiseases.includes('ضغط')}
                                    onChange={handleChange}
                                    className="ker6"
                                />
                            </label>
                            <label className="ker5">
                                {t('PersonalInfoForm.heartDiseases')}
                                <input
                                    type="checkbox"
                                    name="chronicDiseases"
                                    value="أمراض قلب"
                                    checked={formData.chronicDiseases.includes('أمراض قلب')}
                                    onChange={handleChange}
                                    className="ker6"
                                />
                            </label>
                            <label className="ker5">
                                {t('PersonalInfoForm.otherSeriousDiseases')}
                                <input
                                    type="checkbox"
                                    name="chronicDiseases"
                                    value="أمراض خطيرة أخرى"
                                    checked={formData.chronicDiseases.includes('أمراض خطيرة أخرى')}
                                    onChange={handleChange}
                                    className="ker6"
                                />
                            </label>
                        </div>
                    </div>

                    <div className="ker1">
                        <label className="ker2">{t('PersonalInfoForm.smoker')}</label>
                        <div className="ker4 ker-column">
                            <label className="ker-item">
                                {t('PersonalInfoForm.yes')}
                                <input
                                    type="radio"
                                    name="smoker"
                                    value="نعم"
                                    checked={formData.smoker === 'نعم'}
                                    onChange={handleChange}
                                    className="ker6"
                                />
                            </label>
                            <label className="ker-item">
                                {t('PersonalInfoForm.no')}
                                <input
                                    type="radio"
                                    name="smoker"
                                    value="لا"
                                    checked={formData.smoker === 'لا'}
                                    onChange={handleChange}
                                    className="ker6"
                                />
                            </label>
                        </div>
                    </div>
                    <div className="ker1">
                        <label className="ker2">{t('PersonalInfoForm.interests')}</label>
                        <div className="ker4 ker-column">
                            <label className="ker-item">
                                {t('PersonalInfoForm.preventiveTreatments')}
                                <input
                                    type="checkbox"
                                    name="interests"
                                    value="العلاجات الوقائية"
                                    checked={formData.interests.includes('العلاجات الوقائية')}
                                    onChange={handleChange}
                                    className="ker6"
                                />
                            </label>
                            <label className="ker-item">
                                {t('PersonalInfoForm.medicalTechnology')}
                                <input
                                    type="checkbox"
                                    name="interests"
                                    value="التكنولوجيا الطبية"
                                    checked={formData.interests.includes('التكنولوجيا الطبية')}
                                    onChange={handleChange}
                                    className="ker6"
                                />
                            </label>
                            <label className="ker-item">
                                {t('PersonalInfoForm.offersAndDiscounts')}
                                <input
                                    type="checkbox"
                                    name="interests"
                                    value="عروض و خصومات"
                                    checked={formData.interests.includes('عروض و خصومات')}
                                    onChange={handleChange}
                                    className="ker6"
                                />
                            </label>
                            <label className="ker-item">
                                {t('PersonalInfoForm.personalSupportServices')}
                                <input
                                    type="checkbox"
                                    name="interests"
                                    value="خدمات الدعم الشخصي"
                                    checked={formData.interests.includes('خدمات الدعم الشخصي')}
                                    onChange={handleChange}
                                    className="ker6"
                                />
                            </label>
                        </div>
                    </div>
                    <div className="containersyehiye">
                        <button type="submit" className="adou">{t('PersonalInfoForm.submit')}</button>
                    </div>
                </div>
            </form>
            {isModalOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <h2>{t('PersonalInfoForm.passwordVerification')}</h2>
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder={t('PersonalInfoForm.enterPassword')}
                        />
                        <button onClick={handlePasswordSubmit}>{t('PersonalInfoForm.verify')}</button>
                        <button onClick={() => setIsModalOpen(false)}>{t('PersonalInfoForm.cancel')}</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default PersonalInfoForm;

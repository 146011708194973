import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import "./Automation.css";

import back from '../components/images/back2.png';
import { useTranslation } from 'react-i18next';
import icon1Img from '../components/images/icon9.png';
import icon2Img from '../components/images/icon10.png';
import icon3Img from '../components/images/icon11.png';
import icon4Img from '../components/images/icon12.png';
import {  animated } from 'react-spring'; 
 
import { useAuthContext } from '../context/AuthContext';
import { ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon } from '@mui/icons-material';


const Gides = () => { 
 

    const [openArticle, setOpenArticle] = useState(null);

    const toggleArticle = (article) => {
        setOpenArticle(current => current === article ? null : article);
    };

    const toggleSection = (section) => {
        setOpenSection(current => current === section ? null : section);
    };

    const { t, i18n } = useTranslation("global");
    const [openSection, setOpenSection] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const getDirection = () => {
        return i18n.language === "ar" ? "rtl" : "ltr";
    };

    const { user } = useAuthContext();
    return (
        <div className={getDirection() + " backgroundwhiteASS"} style={{ direction: getDirection() }}>

            <div className="bannersol" style={{ backgroundImage: `url(${back})` }}>
            </div>
            <div className="bahite" dir={getDirection(i18n.language)}>
                <br />
                <div>
                </div>
                <div>
                    <p className="GoldCont"> {t("gide.Dina")}</p>
                </div>
                <br />

                <div id="gides">
                    <p className="normalcolapsTextCaption">{t("gide.caption")}</p>
                    <br />

                    <button className="button-collapse" onClick={() => toggleSection('gides')} aria-expanded={openSection === 'gides'}>
                        <animated.span style={{ transform: openSection === 'gides' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#0056B3' }}>
                            {openSection === 'gides' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </animated.span>
                        <p className="textCont">{t("gide.social")}</p>
                    </button>

                    {openSection === 'gides' && (
                        <animated.div style={{
                            backgroundColor: '#F4F6FA',
                            borderBottom: '0.5px solid rgba(0, 0, 0, 0.05)'
                        }}>
                            <div id="gides" className={`ContactU ${openSection === 'gides' ? 'open' : 'closed'}`}>
                                <div className="SocialM">
                                    <div className="smalle">
                                        <img src={icon1Img} alt="Icon 1" />
                                    </div>
                                </div>
                                <div className="SocialM">
                                    <div className="articleContainer">
                                        <div className="articleContent">
                                            <button className="button-collapse" onClick={() => toggleArticle('psyco58')} aria-expanded={openArticle === 'psyco58'}>
                                                <animated.span style={{ transform: openArticle === 'psyco58' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#fdc333' }}>
                                                    {openArticle === 'psyco58' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                                </animated.span>
                                                <p className="textCont">{t("gide.psyco58")}</p>
                                            </button>
                                            {openArticle === 'psyco58' && (
                                                <>
                                                    <p><span className="artt">{t("gide.ourdayt1")}:</span>{t("gide.ourday1")}</p>
                                                    <p><span className="artt">{t("gide.ourdayt2")}:</span>{t("gide.ourday2")}</p>
                                                </>
                                            )}
                                        </div>
                                        <div className="articleContent">
                                            <button className="button-collapse" onClick={() => toggleArticle('iconfam')} aria-expanded={openArticle === 'iconfam'}>
                                                <animated.span style={{ transform: openArticle === 'iconfam' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#fdc333' }}>
                                                    {openArticle === 'iconfam' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                                </animated.span>
                                                <p className="textCont">{t("gide.iconfam")}</p>
                                            </button>
                                            {openArticle === 'iconfam' && (
                                                <>
                                                    <p><span className="artt">{t("gide.fmat1")}:</span>{t("gide.fma1")}</p>
                                                    <p><span className="artt">{t("gide.fmat2")}:</span>{t("gide.fma2")}</p>
                                                </>
                                            )}
                                        </div>
                                        <div className="articleContent">
                                            <button className="button-collapse" onClick={() => toggleArticle('Nutrition')} aria-expanded={openArticle === 'Nutrition'}>
                                                <animated.span style={{ transform: openArticle === 'Nutrition' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#fdc333' }}>
                                                    {openArticle === 'Nutrition' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                                </animated.span>
                                                <p className="textCont">{t("gide.Nutrition")}</p>
                                            </button>
                                            {openArticle === 'Nutrition' && (
                                                <>
                                                    <p><span className="artt">{t("gide.Nutritiont1")}:</span>{t("gide.Nutrition1")}</p>
                                                    <p><span className="artt">{t("gide.Nutritiont2")}:</span>{t("gide.Nutrition2")}</p>
                                                </>
                                            )}
                                        </div>
                                        <div className="articleContent">
                                            <button className="button-collapse" onClick={() => toggleArticle('psyco')} aria-expanded={openArticle === 'psyco'}>
                                                <animated.span style={{ transform: openArticle === 'psyco' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#fdc333' }}>
                                                    {openArticle === 'psyco' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                                </animated.span>
                                                <p className="textCont">{t("gide.psyco")}</p>
                                            </button>
                                            {openArticle === 'psyco' && (
                                                <>
                                                    <p><span className="artt">{t("gide.psycot1")}:</span>{t("gide.psyco1")}</p>
                                                    <p><span className="artt">{t("gide.psycot2")}:</span>{t("gide.psyco2")}</p>
                                                </>
                                            )}
                                        </div>
                                        <div className="articleContent">
                                            <button className="button-collapse" onClick={() => toggleArticle('Pregnancy')} aria-expanded={openArticle === 'Pregnancy'}>
                                                <animated.span style={{ transform: openArticle === 'Pregnancy' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#fdc333' }}>
                                                    {openArticle === 'Pregnancy' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                                </animated.span>
                                                <p className="textCont">{t("gide.Pregnancy")}</p>
                                            </button>
                                            {openArticle === 'Pregnancy' && (
                                                <>
                                                    <p><span className="artt">{t("gide.Pregnancyt1")}:</span>{t("gide.Pregnancy1")}</p>
                                                    <p><span className="artt">{t("gide.Pregnancyt2")}:</span>{t("gide.Pregnancy2")}</p>
                                                </>
                                            )}
                                        </div>
                                        <div className="articleContent">
                                            <button className="button-collapse" onClick={() => toggleArticle('Diseases')} aria-expanded={openArticle === 'Diseases'}>
                                                <animated.span style={{ transform: openArticle === 'Diseases' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#fdc333' }}>
                                                    {openArticle === 'Diseases' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                                </animated.span>
                                                <p className="textCont">{t("gide.Diseases")}</p>
                                            </button>
                                            {openArticle === 'Diseases' && (
                                                <>
                                                    <p><span className="artt">{t("gide.Diseasest1")}:</span>{t("gide.Diseases1")}</p>
                                                </>
                                            )}
                                        </div>
                                        <div className="articleContent">
                                            <button className="button-collapse" onClick={() => toggleArticle('gen')} aria-expanded={openArticle === 'gen'}>
                                                <animated.span style={{ transform: openArticle === 'gen' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#fdc333' }}>
                                                    {openArticle === 'gen' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                                </animated.span>
                                                <p className="textCont">{t("gide.gen")}</p>
                                            </button>
                                            {openArticle === 'gen' && (
                                                <>
                                                    <p>{t("gide.gen1")}</p>
                                                </>
                                            )}
                                        </div>
                                    </div>               

                                    <div className="whitoo">                                            <br />

                                        <button className="blueNewTkhile">
                                            <Link to={user ? "/" : "/signup"}>
                                                <p className="textwhite" onClick={() => window.scrollTo(0, 0)}>{t("gide.Review")}</p>
                                            </Link>
                                        </button>
                                        <br />
                                    </div>
                                </div>
                            </div>
                        </animated.div>
                    )}
                </div>


                <div id="ContactU">

                    <button className="button-collapse" onClick={() => toggleSection('ContactU')} aria-expanded={openSection === 'ContactU'}>
                        <animated.span style={{ transform: openSection === 'ContactU' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#0056B3' }}>
                            {openSection === 'ContactU' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </animated.span>
                        <p className="textCont">{t("gide.planTitle")}</p>
                    </button>

                    {openSection === 'ContactU' && (
                        <animated.div style={{
                            backgroundColor: '#F4F6FA',
                            borderBottom: '0.5px solid rgba(0, 0, 0, 0.05)'
                        }}>
                            <div id="ContactU" className={`ContactU ${openSection === 'ContactU' ? 'open' : 'closed'}`}>
                                <div className="SocialM">
                                    <div className="smalle">
                                        <img src={icon2Img} alt="Icon 2" />
                                    </div>
                                </div>
                                <div className="SocialM">
                                    <div className="articleContainer">
                                        <div className="articleContent">
                                            <button className="button-collapse" onClick={() => toggleArticle('dctt')} aria-expanded={openArticle === 'dctt'}>
                                                <animated.span style={{ transform: openArticle === 'dctt' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#fdc333' }}>
                                                    {openArticle === 'dctt' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                                </animated.span>
                                                <p className="textCont">{t("gide.dctt")}</p>
                                            </button>
                                            {openArticle === 'dctt' && (
                                                <>
                                                    <p>{t("gide.dct")}</p>
                                                </>
                                            )}
                                        </div>
                                        <div className="articleContent">
                                            <button className="button-collapse" onClick={() => toggleArticle('pregT')} aria-expanded={openArticle === 'pregT'}>
                                                <animated.span style={{ transform: openArticle === 'pregT' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#fdc333' }}>
                                                    {openArticle === 'pregT' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                                </animated.span>
                                                <p className="textCont">{t("gide.pregT")}</p>
                                            </button>
                                            {openArticle === 'pregT' && (
                                                <>
                                                    <p>{t("gide.preg")}</p>
                                                </>
                                            )}
                                        </div>
                                        <div className="articleContent">
                                            <button className="button-collapse" onClick={() => toggleArticle('mutT')} aria-expanded={openArticle === 'mutT'}>
                                                <animated.span style={{ transform: openArticle === 'mutT' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#fdc333' }}>
                                                    {openArticle === 'mutT' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                                </animated.span>
                                                <p className="textCont">{t("gide.mutT")}</p>
                                            </button>
                                            {openArticle === 'mutT' && (
                                                <>
                                                    <p>{t("gide.mut")}</p>
                                                </>
                                            )}
                                        </div>
                                        <div className="articleContent">
                                            <button className="button-collapse" onClick={() => toggleArticle('picT')} aria-expanded={openArticle === 'picT'}>
                                                <animated.span style={{ transform: openArticle === 'picT' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#fdc333' }}>
                                                    {openArticle === 'picT' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                                </animated.span>
                                                <p className="textCont">{t("gide.picT")}</p>
                                            </button>
                                            {openArticle === 'picT' && (
                                                <>
                                                    <p>{t("gide.pic")}</p>
                                                </>
                                            )}
                                        </div>
                                        <div className="articleContent">
                                            <button className="button-collapse" onClick={() => toggleArticle('NutritionT')} aria-expanded={openArticle === 'NutritionT'}>
                                                <animated.span style={{ transform: openArticle === 'NutritionT' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#fdc333' }}>
                                                    {openArticle === 'NutritionT' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                                </animated.span>
                                                <p className="textCont">{t("gide.NutritionT")}</p>
                                            </button>
                                            {openArticle === 'NutritionT' && (
                                                <>
                                                    <p>{t("gide.Nutritiont")}</p>
                                                </>
                                            )}
                                        </div>           

                                        <div className="whitoo">                                            <br />

                                            <button className="blueNewTkhile">
                                                <Link to={user ? "/" : "/signup"}>
                                                    <p className="textwhite" onClick={() => window.scrollTo(0, 0)}>{t("gide.Review")}</p>
                                                </Link>
                                            </button>
                                            <br />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </animated.div>
                    )}
                </div>



                <div id="ourdata">

                    <button className="button-collapse" onClick={() => toggleSection('ourdata')} aria-expanded={openSection === 'ourdata'}>
                        <animated.span style={{ transform: openSection === 'ourdata' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#0056B3' }}>
                            {openSection === 'ourdata' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </animated.span>
                        <p className="textCont">{t("gide.birthT")}</p>
                    </button>

                    {openSection === 'ourdata' && (
                        <animated.div style={{
                            backgroundColor: '#F4F6FA',
                            borderBottom: '0.5px solid rgba(0, 0, 0, 0.05)'
                        }}>
                            <div id="ourdata" className={`ContactU ${openSection === 'ourdata' ? 'open' : 'closed'}`}>
                                <div className="SocialM">
                                    <div className="smalle">
                                        <img src={icon3Img} alt="Icon 3" />
                                    </div>
                                </div>
                                <div className="SocialM">
                                    <div className="articleContainer">
                                        <div className="articleContent">
                                            <button className="button-collapse" onClick={() => toggleArticle('hospitalT')} aria-expanded={openArticle === 'hospitalT'}>
                                                <animated.span style={{ transform: openArticle === 'hospitalT' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#fdc333' }}>
                                                    {openArticle === 'hospitalT' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                                </animated.span>
                                                <p className="textCont">{t("gide.hospitalT")}</p>
                                            </button>
                                            {openArticle === 'hospitalT' && (
                                                <>
                                                    <p>{t("gide.hospitaltext")}</p>
                                                </>
                                            )}
                                        </div>
                                        <div className="articleContent">
                                            <button className="button-collapse" onClick={() => toggleArticle('paintitle')} aria-expanded={openArticle === 'paintitle'}>
                                                <animated.span style={{ transform: openArticle === 'paintitle' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#fdc333' }}>
                                                    {openArticle === 'paintitle' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                                </animated.span>
                                                <p className="textCont">{t("gide.paintitle")}</p>
                                            </button>
                                            {openArticle === 'paintitle' && (
                                                <>
                                                    <p>{t("gide.paintext")}</p>
                                                </>
                                            )}
                                        </div>
                                        <div className="articleContent">
                                            <button className="button-collapse" onClick={() => toggleArticle('stresedTitle')} aria-expanded={openArticle === 'stresedTitle'}>
                                                <animated.span style={{ transform: openArticle === 'stresedTitle' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#fdc333' }}>
                                                    {openArticle === 'stresedTitle' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                                </animated.span>
                                                <p className="textCont">{t("gide.stresedTitle")}</p>
                                            </button>
                                            {openArticle === 'stresedTitle' && (
                                                <>
                                                    <p>{t("gide.stresedtext")}</p>
                                                </>
                                            )}
                                        </div>
                                        <div className="articleContent">
                                            <button className="button-collapse" onClick={() => toggleArticle('houseTitle')} aria-expanded={openArticle === 'houseTitle'}>
                                                <animated.span style={{ transform: openArticle === 'houseTitle' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#fdc333' }}>
                                                    {openArticle === 'houseTitle' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                                </animated.span>
                                                <p className="textCont">{t("gide.houseTitle")}</p>
                                            </button>
                                            {openArticle === 'houseTitle' && (
                                                <>
                                                    <p>{t("gide.housetext")}</p>
                                                </>
                                            )}
                                        </div>               

                                        <div className="whitoo">                                            <br />

                                            <button className="blueNewTkhile">
                                                <a href="https://api.whatsapp.com/send?phone=+966571605516&text=Hello!">
                                                    <p className="textwhite">{t("gide.Review")}</p>
                                                </a>
                                            </button>
                                            <br />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </animated.div>
                    )}
                </div>

                <div id="addservice">

                    <button className="button-collapse" onClick={() => toggleSection('addservice')} aria-expanded={openSection === 'addservice'}>
                        <animated.span style={{ transform: openSection === 'addservice' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#0056B3' }}>
                            {openSection === 'addservice' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </animated.span>
                        <p className="textCont">{t("gide.servicesT")}</p>
                    </button>

                    {openSection === 'addservice' && (
                        <animated.div style={{
                            backgroundColor: '#F4F6FA',
                            borderBottom: '0.5px solid rgba(0, 0, 0, 0.05)'
                        }}>
                            <div id="addservice" className={`ContactU ${openSection === 'addservice' ? 'open' : 'closed'}`}>
                                <div className="SocialM">
                                    <div className="smalle">
                                        <img src={icon4Img} alt="Icon 4" />
                                    </div>
                                </div>
                                <div className="SocialM">
                                    <div className="articleContainer">
                                        <div className="articleContent">
                                            <button className="button-collapse" onClick={() => toggleArticle('massageT')} aria-expanded={openArticle === 'massageT'}>
                                                <animated.span style={{ transform: openArticle === 'massageT' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#fdc333' }}>
                                                    {openArticle === 'massageT' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                                </animated.span>
                                                <p className="textCont">{t("gide.massageT")}</p>
                                            </button>
                                            {openArticle === 'massageT' && (
                                                <>
                                                    <p>{t("gide.massagetext")}</p>
                                                </>
                                            )}
                                        </div>
                                        <div className="articleContent">
                                            <button className="button-collapse" onClick={() => toggleArticle('personalT')} aria-expanded={openArticle === 'personalT'}>
                                                <animated.span style={{ transform: openArticle === 'personalT' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#fdc333' }}>
                                                    {openArticle === 'personalT' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                                </animated.span>
                                                <p className="textCont">{t("gide.personalT")}</p>
                                            </button>
                                            {openArticle === 'personalT' && (
                                                <>
                                                    <p>{t("gide.personaltext")}</p>
                                                </>
                                            )}
                                        </div>
                                        <div className="articleContent">
                                            <button className="button-collapse" onClick={() => toggleArticle('partyT')} aria-expanded={openArticle === 'partyT'}>
                                                <animated.span style={{ transform: openArticle === 'partyT' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#fdc333' }}>
                                                    {openArticle === 'partyT' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                                </animated.span>
                                                <p className="textCont">{t("gide.partyT")}</p>
                                            </button>
                                            {openArticle === 'partyT' && (
                                                <>
                                                    <p>{t("gide.partytext")}</p>
                                                </>
                                            )}
                                        </div>
                                        <div className="articleContent">
                                            <button className="button-collapse" onClick={() => toggleArticle('PartyTitleRecived')} aria-expanded={openArticle === 'PartyTitleRecived'}>
                                                <animated.span style={{ transform: openArticle === 'PartyTitleRecived' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#fdc333' }}>
                                                    {openArticle === 'PartyTitleRecived' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                                </animated.span>
                                                <p className="textCont">{t("gide.PartyTitleRecived")}</p>
                                            </button>
                                            {openArticle === 'PartyTitleRecived' && (
                                                <>
                                                    <p>{t("gide.PartyTitleRecivedtext")}</p>
                                                </>
                                            )}
                                        </div>
                                        <div className="articleContent">
                                            <button className="button-collapse" onClick={() => toggleArticle('PreparingT')} aria-expanded={openArticle === 'PreparingT'}>
                                                <animated.span style={{ transform: openArticle === 'PreparingT' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#fdc333' }}>
                                                    {openArticle === 'PreparingT' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                                </animated.span>
                                                <p className="textCont">{t("gide.PreparingT")}</p>
                                            </button>
                                            {openArticle === 'PreparingT' && (
                                                <>
                                                    <p>{t("gide.Preparingtext")}</p>
                                                </>
                                            )}
                                        </div>
                                        <div className="articleContent">
                                            <button className="button-collapse" onClick={() => toggleArticle('PhotographyT')} aria-expanded={openArticle === 'PhotographyT'}>
                                                <animated.span style={{ transform: openArticle === 'PhotographyT' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#fdc333' }}>
                                                    {openArticle === 'PhotographyT' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                                </animated.span>
                                                <p className="textCont">{t("gide.PhotographyT")}</p>
                                            </button>
                                            {openArticle === 'PhotographyT' && (
                                                <>
                                                    <p>{t("gide.Photographytext")}</p>
                                                </>
                                            )}
                                        </div>              

                                        <div className="whitoo">                                            <br />

                                            
                                            <button className="blueNewTkhile">
                                                <a href="https://api.whatsapp.com/send?phone=+966571605516&text=Hello!">
                                                    <p className="textwhite">{t("gide.Review")}</p>
                                                </a>
                                            </button>
                                            <br />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </animated.div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Gides;


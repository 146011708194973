import './Obstetrics_and_Gynecology.css';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import icon1Img from './components/images/imgprice.png'; 
import icon2Img from './components/images/imghome.png';
import icon3Img from './components/images/imgdown.png';
import icon4Img from './components/images/location.png';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'; 

const Obstetrics_and_GynecologyList = () => {
  const { t, i18n } = useTranslation("global");
  const [calls, setCalls] = useState([]);
   const [filteredCalls, setFilteredCalls] = useState([]);
  const [location, setLocation] = useState('');
  const [naturalBirth, setNaturalBirth] = useState('');
  const [roomType] = useState('');
  const [service, setService] = useState('');
  const [loading, setLoading] = useState(false);
  const [searchCompleted, setSearchCompleted] = useState(false);
  const [serviceRequired, setServiceRequired] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const isButtonDisabled = !location && !naturalBirth && !roomType && !service;
  const [currentImageIndex, setCurrentImageIndex] = useState({});
  const [monthValue, setMonthValue] = useState('');
  const serviceToMonthMap = {
    '5': 1,
    '-1': 2,
    '-2': 3,
    '-5': 4,
    '-7': 5,
    '-9': 6,
    '-11': 7,
    '-15': 8,
    '-17': 9,
  };
  const validServices = ["5", "-1", "-2", "-5", "-7", "-9", "-11", "-15", "-17"];

  const getDirection = (reverse = false) => {
    const baseDirection = i18n.language === "ar" ? "rtl" : "ltr";
    return reverse ? (baseDirection === "rtl" ? "ltr" : "rtl") : baseDirection;
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    console.log('ServiceuseMemo selected:', service);

    if (!service || !validServices.includes(service)) {
      setServiceRequired(true);
      setErrorMessage(t("Obstetrics_and_Gynecology.selectServiceMessage"));
      console.log('Service required set to true');
      return;
    }

    setServiceRequired(false);
    setErrorMessage('');
    console.log('Service required set to false');
    setLoading(true);
    const language = i18n.language === "ar" ? "Ar" : "En";
    const data = await import(`./translation/${language}/Call.json`);
    const calls = data.default;
    console.log('Data loaded:', calls);

    const filtered = calls.filter(service => {
      return (
        (location ? service.Location.toLowerCase() === location.toLowerCase() : true) &&
        (naturalBirth ? service.Services.toLowerCase() === naturalBirth.toLowerCase() : true) &&
        (roomType ? service.RoomType.toLowerCase() === roomType.toLowerCase() : true)
      );
    });

    console.log('Filtered data:', filtered);

    setCalls(calls); 
    setFilteredCalls(filtered);
    setLoading(false);
    setSearchCompleted(true);
  };

  const getTitleDescription = (title) => t(`Obstetrics_and_Gynecology.${title}`);
  const getBranchDescription = (Branch) => t(`Obstetrics_and_Gynecology.${Branch}`);
  const getNaturalBirthDescription = (serviceName) => t(`Obstetrics_and_Gynecology.${serviceName}`);
  const getRoomTypeDescription = (roomType) => t(`Obstetrics_and_Gynecology.${roomType}`);

  const calculatePrice = (basePrice, discountPercentage) => {
    return basePrice - (basePrice * (Number(discountPercentage) / 100));
  };
  const handleNextImage = (callId, imageCount) => {
    setCurrentImageIndex((prev) => {
      const nextIndex = (prev[callId] || 0) + 1;
      return {
        ...prev,
        [callId]: nextIndex >= imageCount ? 0 : nextIndex,
      };
    });
    setActiveButton('right');

  };
  const [activeButton, setActiveButton] = useState(null);


  const handlePreviousImage = (callId, imageCount) => {
    setCurrentImageIndex((prev) => {
      const prevIndex = (prev[callId] || 0) - 1;
      return {
        ...prev,
        [callId]: prevIndex < 0 ? imageCount - 1 : prevIndex,
      };
    });
    setActiveButton('left');

  };
  const handleServiceChange = (e) => {
    const selectedService = e.target.value;
    setService(selectedService);

    const calculatedMonth = serviceToMonthMap[selectedService] || '';
    setMonthValue(calculatedMonth);
    console.log("Selected month value:", calculatedMonth);
  };
  return (
    <div dir={getDirection()} className='bluethore'>

      <div className='desktop'>
        <div className='forhead'>
          <br />
          <h1 className="TitlContwhab">{t("Obstetrics_and_Gynecology.call0")}</h1>
          <h1 className="TitlContKhafet">{t("Obstetrics_and_Gynecology.call11")}</h1>
        </div>
        <div className='backwazb'>
          <form onSubmit={handleSearch} className="search-form">
            <div className="rowcall">
              <div class="custom-select-conte">
                <select id="LocationSelect" value={location} onChange={e => setLocation(e.target.value)}>
                  <option value="">{t("Obstetrics_and_Gynecology.ChoseLoc")}</option>
                  <option value="Riyadh">{t("Obstetrics_and_Gynecology.Riyadh")}</option>
                  <option value="Jeddah">{t("Obstetrics_and_Gynecology.Jeddah")}</option>
                  <option value="Mecca">{t("Obstetrics_and_Gynecology.Mecca")}</option>
                </select>
              </div>
              <div className='shusar'> </div>
              <div class="custom-select-container">

                <select id="NaturalBirthSelect" value={naturalBirth} onChange={(e) => setNaturalBirth(e.target.value)}>
                  <option value="">{t("Obstetrics_and_Gynecology.cal5")}</option>
                  <option value="normal">{t("Obstetrics_and_Gynecology.cal6")}</option>
                  <option value="k1">{t("Obstetrics_and_Gynecology.cal7")}</option>
                  <option value="k2">{t("Obstetrics_and_Gynecology.cal8")}</option>
                  <option value="k3">{t("Obstetrics_and_Gynecology.cal9")}</option>
                  <option value="k4">{t("Obstetrics_and_Gynecology.cal10")}</option>
                  <option value="k5">{t("Obstetrics_and_Gynecology.cal11")}</option>
                  <option value="t2">{t("Obstetrics_and_Gynecology.cal12")}</option>
                  <option value="nw">{t("Obstetrics_and_Gynecology.cal12_1")}</option>
                  <option value="k1t">{t("Obstetrics_and_Gynecology.cal12_2")}</option>
                </select>
              </div>
              <div className='shusar'> </div>

              <div class="custom-select-idba">
                <select id="service" value={service} onChange={handleServiceChange}>
                  <option value="">{t("Obstetrics_and_Gynecology.cal18")}</option>
                  <option value="5">{t("Obstetrics_and_Gynecology.cal19")}</option>
                  <option value="-1">{t("Obstetrics_and_Gynecology.cal20")}</option>
                  <option value="-2">{t("Obstetrics_and_Gynecology.cal21")}</option>
                  <option value="-5">{t("Obstetrics_and_Gynecology.cal22")}</option>
                  <option value="-7">{t("Obstetrics_and_Gynecology.cal23")}</option>
                  <option value="-9">{t("Obstetrics_and_Gynecology.cal24")}</option>
                  <option value="-11">{t("Obstetrics_and_Gynecology.cal25")}</option>
                  <option value="-15">{t("Obstetrics_and_Gynecology.cal26")}</option>
                  <option value="-17">{t("Obstetrics_and_Gynecology.cal27")}</option>
                </select>
              </div>

              <div className='shusar'> </div>

              <div className='GREEN' >
                <button className='blueNewtGREEN' type="submit"
                  disabled={isButtonDisabled}>
                  <span className="capital">
                    {t("Obstetrics_and_Gynecology.cal36")}
                  </span>
                  <span>
                    {t("Obstetrics_and_Gynecology.earch")}
                  </span>
                </button>


              </div>

            </div>

            {serviceRequired && <p className="error-message">{errorMessage}</p>}
          </form>
        </div>
      </div>
      {loading ? <p>{t("Obstetrics_and_Gynecology.cal28")}</p> : null}

      {searchCompleted && !loading && filteredCalls.length === 0 ? (
        <div>
          <p>{t("Obstetrics_and_Gynecology.noServiceAvailable")}</p>
        </div>
      ) : null}

      {searchCompleted && !loading && filteredCalls.length > 0 ? (
        <div className='Desplay'>
          <table className='dektopDes'>
            <thead>
              <tr >
                <th></th>
                <th dir={getDirection(true)}> {t("Obstetrics_and_Gynecology.cal29")}</th>
                <th>{t("Obstetrics_and_Gynecology.cal30")}</th>
                <th>{t("Obstetrics_and_Gynecology.cal27_1")}</th>
                <th>{t("Obstetrics_and_Gynecology.cal31")}</th>
                <th>{t("Obstetrics_and_Gynecology.cal32")}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {filteredCalls.map(call => (
                <tr key={call.id}>
                  <td><img src={call.image1} alt="Call Visual Representation" /></td>
                  <td>
                    <span>{getTitleDescription(call.Title)}</span><br />
                    <span className="branchDescription" >
                      {getBranchDescription(call.Branch)}
                    </span>
                  </td>
                  <td className='murafek'>{getNaturalBirthDescription(call.Services)}</td>
                  <td className='nzabta'>{calculatePrice(call.PriceAtYourPlatform, service).toFixed(2)}&nbsp;{t("Services.Pur6")}</td>
                  <td>{getRoomTypeDescription(call.RoomType)}</td>
                  <td>{(call.PriceAtHospital - calculatePrice(call.PriceAtYourPlatform, service)).toFixed(2)}&nbsp;{t("Services.Pur6")}</td>
                  <td >
                    <Link
                      to="/details"

                      state={{
                        data: call.id, type: 'call', service, roomType: call.RoomType, monthValue: monthValue
                      }}
                      onClick={() => console.log('Navigating to details with:', { data: call.id, type: 'call', service, roomType: call.RoomType })}
                      className='aligndiae'
                    > 
                      <button className="blueNewTkhile">{t("Obstetrics_and_Gynecology.cal35")}</button>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className='mobileDes' dir={getDirection(i18n.language)}>
            {filteredCalls.map(call => {
              const images = [call.image4, call.image2, call.image3].filter(Boolean); // Only take non-empty images
              const currentImage = images[currentImageIndex[call.id] || 0]; // Default to the first image if no index is stored
              const imageCount = images.length;

              return (
                <div className="cardsContainerma" key={call.id}>
                  <div className="cardsakhif">

                    <br />
                    <div className='articleContt'>
                      <div className='imagehosk'>
                        <img src={currentImage} alt="wait or refresh the website" />
                        <div className="butrow">
                          <button
                            className={`left-button ${activeButton === 'left' ? 'active' : ''}`}
                            onClick={() => handlePreviousImage(call.id, imageCount)}
                          >
                            <FontAwesomeIcon icon={faChevronLeft} className="smacon" />
                          </button>
                          <button
                            className={`right-button ${activeButton === 'right' ? 'active' : ''}`}
                            onClick={() => handleNextImage(call.id, imageCount)}
                          >
                            <FontAwesomeIcon icon={faChevronRight} className="smacon" />
                          </button>
                        </div>

                      </div>
                    </div>

                    <div className='card-actions'>
                      <div className='from_start'>
                        <p className='mumtaza'>

                          {getBranchDescription(call.Branch)}
                          &nbsp; <p>,</p>
                          {getBranchDescription(call.Location)}

                          &nbsp;

                          <img className="servobile" src={icon4Img} alt="wait or refresh the website"  />

                        </p>
                        {/* ///////////////////////////////////////////////// */}
                      </div>
                      &nbsp;


                      {/* <div className='hanaback'> */}
                      <div className='textContainer'>
                        <p className='from_startwar' dir={getDirection(call.reverse)}>
                          {getTitleDescription(call.Title)}
                        </p>
                      </div>
                      &nbsp;

                      {/* <img src={call.image1} alt="wait or refresh the website" /> */}
                      {/* </div> */}
                      <div className='sertit'>
                        {getNaturalBirthDescription(call.Services)}

                      </div>
                      &nbsp;

                      <div className='from_start'>
                        <p className='from_startbaba'>

                          <img className="servobile" src={icon1Img}  alt="wait or refresh the website" />
                          <p className='fuctsm'>{t("Services.Pur6")}</p>

                          &nbsp;
                          &nbsp;
                          {calculatePrice(call.PriceAtYourPlatform, service).toFixed(2)}

                          <p className='fucsm'>{t("Services.Pur6")}</p>

                          &nbsp;


                          <img className="servobile" src={icon2Img}  alt="wait or refresh the website"  />

                          {getRoomTypeDescription(call.RoomType)}
                          &nbsp;

                          <img className="servobile" src={icon3Img}  />
                          <p className='fuctsm'>{t("Services.Pur6")}</p>

                          &nbsp;
                          &nbsp;
                          {(call.PriceAtHospital - calculatePrice(call.PriceAtYourPlatform, service)).toFixed(2)}

                          <p className='fucsm'>{t("Services.Pur6")}</p>

                          &nbsp;

                        </p>
                      </div>

                      &nbsp;

                      <Link
                        to="/details"
                        state={{
                          data: call.id, type: 'call', service, roomType: call.RoomType, monthValue: monthValue // Adding the calculated month value here
                        }}
                        onClick={() => console.log('Navigating to details with:', { data: call.id, type: 'call', service, roomType: call.RoomType })}
                        className='aligndiae'
                      >
                        <button className='blfuewTkhile'>{t("Obstetrics_and_Gynecology.cal35")}</button>
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

        </div>
      ) : null}
    </div>
  );
};

export default Obstetrics_and_GynecologyList;

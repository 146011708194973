import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Input } from "@mui/material";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import SendIcon from "@mui/icons-material/Send";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useTranslation } from "react-i18next";
import "./SignUp.css";

const SignUp = () => {
  const { t, i18n } = useTranslation("global");
  const navigate = useNavigate();
  const [passwordMismatch, setPasswordMismatch] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [registrationError, setRegistrationError] = useState("");
  const [otpSent, setOtpSent] = useState(false); 
  const [otp, setOtp] = useState(""); 
  const [otpError, setOtpError] = useState(""); 
  const [otpVerified, setOtpVerified] = useState(false);
  const [userPhone, setUserPhone] = useState(""); 
  const [appointmentInfo, setAppointmentInfo] = useState(null); 

  const handleSubmit = async (e) => {
    e.preventDefault();
    const {
      firstName: { value: firstName },
      lastName: { value: lastName },
      phone: { value: phone },
      password: { value: password },
      confirmPassword: { value: confirmPassword },
    } = e.target.elements;

    setPasswordMismatch(false);
    setPasswordError("");
    setPhoneError("");
    setRegistrationError("");

    // Check if passwords match
    if (password !== confirmPassword) {
      setPasswordMismatch(true);
      setPasswordError(t("SignUp.passwordMismatch"));
      return;
    }

    // Check password length
    if (password.length < 6) {
      setPasswordMismatch(true);
      setPasswordError(t("SignUp.passwordRequirement"));
      return;
    }

    // Validate phone number (Saudi format)
    if (!/^05\d{8}$/.test(phone)) {
      setPhoneError(t("SignUp.invalidPhone"));
      return;
    }

    try {
      const registerUserUrl = process.env.REACT_APP_REGISTER_USER_URL;

      const response = await fetch(registerUserUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          firstName: firstName.trim(),
          lastName: lastName.trim(),
          phone: phone.trim(),
          password: password.trim(),
        }),
      });

      const data = await response.json();

      if (data.status === "success") {
        console.log("Verified OTP for phone:", userPhone); 

        setOtpSent(true);
        setUserPhone(phone.trim()); 
        setRegistrationError("");
        alert(data.message);
      } else if (data.status === "error" && data.message.includes("User with this phone already exists")) {
        setRegistrationError(t("SignUp.userAlreadyExists"));
      } else {
        setRegistrationError(data.message || t("SignUp.unexpectedError"));
      }
    } catch (error) {
      console.error("Registration error:", error);
      setRegistrationError(t("SignUp.unexpectedError"));
    }

  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();

    if (!otp) {
      setOtpError(t("SignUp.invalidOtp"));
      return;
    }

    try {
      const verifyOtpUrl = process.env.REACT_APP_VERIFY_OTP_URL;

      const response = await fetch(verifyOtpUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          otp: otp.trim(),
          phone: userPhone,
        }),
      });

      const data = await response.json();

      if (data.status === "success") {
        setOtpVerified(true);
        alert("OTP verified successfully. Your account has been activated.");

        const savedAppointment = localStorage.getItem("appointmentInfo");
        if (savedAppointment) {
          const appointment = JSON.parse(savedAppointment);
          localStorage.removeItem("appointmentInfo"); 

          navigate("/My_appointments", { state: appointment });
        } else {
          navigate("/home", { replace: true });
        }

        window.location.reload();
      } else {
        setOtpError(data.message || t("SignUp.unexpectedOtpError"));
      }
    } catch (error) {
      console.error("OTP verification error:", error);
      setOtpError(t("SignUp.unexpectedOtpError"));
    }

  };

  const getDirection = () => {
    return i18n.language === "ar" ? "rtl" : "ltr";
  };

  useEffect(() => {
    const savedAppointment = localStorage.getItem('appointmentInfo');
    if (savedAppointment) {
      setAppointmentInfo(JSON.parse(savedAppointment));
      localStorage.removeItem('appointmentInfo');
    }
  }, [otpVerified]);

  return (
    <div className="signup-container" style={{ direction: getDirection() }}>
      {!otpSent ? (
        <form className="signup-form" onSubmit={handleSubmit}>
          <div className="title">
          <p className="TitlContw aligne" style={{ color: "#072C7B", fontSize: "24px" }}>
          {t("SignUp.Title")}
          </p>

          </div>
          <div className="name-container">
            <div className="faragh">
              <label htmlFor="firstName">{t("SignUp.FirstName")}</label>
              <Input
                id="firstName"
                name="firstName"
                placeholder={t("SignUp.placeholdersfirstName")}
                type="text"
                required
              />
            </div>
            <div className="faragh">
              <label htmlFor="lastName">{t("SignUp.LastName")}</label>
              <Input
                id="lastName"
                name="lastName"
                placeholder={t("SignUp.placeholderslastName")}
                type="text"
                required
              />
            </div>
          </div>
          <div className="center-section">
            <div>
              <label htmlFor="phone">{t("SignUp.Phone")}</label>
              <Input
                id="phone"
                name="phone"
                placeholder={t("SignUp.placeholdersPhone")}
                required
                inputProps={{ style: { direction: "rtl", textAlign: "right" } }}
              />
              {phoneError && (
                <p className="error-message">
                  <ErrorOutlineIcon style={{ marginRight: "5px" }} />
                  {phoneError}
                </p>
              )}
            </div>
            <div>
              <label htmlFor="password">{t("SignUp.Password")}</label>
              <Input
                id="password"
                name="password"
                placeholder={t("SignUp.placeholderspassword")}
                type="password"
                required
              />
            </div>
            <div>
              <label htmlFor="confirmPassword">{t("SignUp.ConfirmPassword")}</label>
              <Input
                id="confirmPassword"
                name="confirmPassword"
                placeholder={t("SignUp.placeholderspassword")}
                type="password"
                required
              />
            </div>
            {passwordMismatch && (
              <p className="error-message">
                <ErrorOutlineIcon style={{ marginRight: "5px" }} />
                {passwordError}
              </p>
            )}
            {registrationError && (
              <p className="error-message">
                <ErrorOutlineIcon style={{ marginRight: "5px" }} />
                {registrationError}
              </p>
            )}
            <button type="submit" className="Registration-btn">
              <AppRegistrationIcon />
              {t("SignUp.Registration")}
            </button>
          </div>


        </form>

      ) : (
        <form className="otp-form" onSubmit={handleOtpSubmit}>
          <div className="otp-form-header">
            <h2 className="otp-form-title">{t("SignUp.OTPVerification")}</h2>
            <p className="otp-form-subtitle">{t("SignUp.OTPInstructions")}</p>
          </div>

          <div className="form-group otp-input-group">
            <div className="otp-container" style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "10px" }}>
              <label htmlFor="otp" className="otp-label">
                {t("SignUp.EnterOTP")}
              </label>
              <Input
                id="otp"
                name="otp"
                className="otp-input"
                placeholder={t("SignUp.placeholdersOTP")}
                type="text"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                required
                autoComplete="off"
                inputProps={{ maxLength: 6 }}
                style={{
                  letterSpacing: "0.2em",
                  textAlign: "center",
                  width: "120px",
                  padding: "10px",
                  border: "2px solid #ccc",
                  borderRadius: "8px",
                }}
              />
            </div>
          </div>

          {otpError && (
            <p className="error-message" style={{ color: "red", textAlign: "center", marginTop: "10px" }}>
              <ErrorOutlineIcon style={{ marginRight: "5px", verticalAlign: "middle" }} />
              {otpError}
            </p>
          )}

          <button
            type="submit"
            className="otp-submit-btn"
            style={{
              backgroundColor: "#072C7B",
              color: "white",
              border: "none",
              borderRadius: "8px",
              cursor: "pointer",
              width: "100%",
              marginTop: "15px",
              transition: "background-color 0.3s ease",
            }}
            onMouseOver={(e) => (e.target.style.backgroundColor = "#0A417A")}
            onMouseOut={(e) => (e.target.style.backgroundColor = "#072C7B")}
          >
            {t("SignUp.VerifyOTP")}
          </button>
        </form>
      )}

      {appointmentInfo && otpVerified && (
        <div>
          <button
            className="blueNewTkhile"
            onClick={() => {
              window.location.href = "/My_appointments";
            }}
          >
            {t("Calendar.goToAppointments")}
          </button>
        </div>
      )}

      <button className="login-btn" onClick={() => navigate("/login")} endIcon={<SendIcon />}>
        {t("SignUp.LoginScreen")}
      </button>
    </div>
  );
};

export default SignUp;

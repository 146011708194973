import React, { useEffect } from "react";
import { useTranslation } from 'react-i18next';

const Responsible = () => {
    const { t, i18n } = useTranslation("global");

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const getDirection = () => {
        return i18n.language === "ar" ? "rtl" : "ltr";
    };

    return (
        <div style={{ direction: getDirection(), backgroundColor: "white", padding: "20px", borderRadius: "8px" }}>
            <h1>{t("Responsible.title")}</h1>
            <div>
                <h2>{t("Responsible.section1")}</h2>
                <p>{t("Responsible.text1")}</p>
            </div>
            <br/>
            <br/>
            <div >
                <h2>{t("Responsible.section2")}</h2>
                <p>{t("Responsible.text2")}</p>
            </div>
            <br/>
            <br/>
            <div >
                <h2>{t("Responsible.section3")}</h2>
                <p>{t("Responsible.text3")}</p>
            </div>
            <br/>
            <br/>
            <div >
                <h2>{t("Responsible.section4")}</h2>
                <p>{t("Responsible.text4")}</p>
            </div>
            <br/>
            <br/>
            <div >
                <h2>{t("Responsible.section5")}</h2>
                <p>{t("Responsible.text5")}</p>
            </div>
            <br/>
            <br/>
            <div >
                <h2>{t("Responsible.section6")}</h2>
                <p>{t("Responsible.text6")}</p>
            </div>
            <br/>
            <br/>
            <div >
                <h2>{t("Responsible.section7")}</h2>
                <p>{t("Responsible.text7")}</p>
            </div>
            <br/>
            <br/>
            <div >
                <h2>{t("Responsible.section8")}</h2>
                <p>{t("Responsible.text8")}</p>
            </div>

        </div>
    );
};

export default Responsible;

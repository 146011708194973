import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './Borde.css';
import { useTranslation } from 'react-i18next';
import { IoEyeOffOutline } from 'react-icons/io5'; // Import the close icon

const Borde = ({ setIsLoading }) => {
  const { t, i18n } = useTranslation("global");
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const [debouncedQuery, setDebouncedQuery] = useState(searchQuery);

  const [filteredServices1, setFilteredServices1] = useState([]);
  const [additionalServices1, setAdditionalServices1] = useState([]);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const dropdownRef = useRef(null); // Ref to track the dropdown

  // Close the dropdown when clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownVisible(false); // Close the dropdown
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  // Fetch additional services
  useEffect(() => {
    const loadData1 = async () => {
      try {
        const response1 = await fetch(process.env.REACT_APP_API_URL);
        const data1 = await response1.json();

        if (Array.isArray(data1)) {
          setAdditionalServices1(data1);
        } else {
          console.error("Data1 is not in the expected format (array)");
          setAdditionalServices1([]);
        }
      } catch (error) {
        console.error("Error loading additional services data:", error);
      }
    };

    loadData1();
  }, []);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedQuery(searchQuery);
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [searchQuery]);

  useEffect(() => {
    if (debouncedQuery && Array.isArray(additionalServices1)) {
      const filtered = additionalServices1.filter(service => {
        const title = service.title?.toLowerCase() || '';
        const summary = service.summary?.toLowerCase() || '';
        const includedServices = service.included_services?.toLowerCase() || '';

        return (
          title.includes(debouncedQuery.toLowerCase()) ||
          summary.includes(debouncedQuery.toLowerCase()) ||
          includedServices.includes(debouncedQuery.toLowerCase())
        );
      });
      setFilteredServices1(filtered);
    } else {
      setFilteredServices1([]);
    }
  }, [debouncedQuery, additionalServices1]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setIsDropdownVisible(true); // Show the dropdown when typing
  };

  const handleServiceClick = (service) => {
    if (service && service.service_id) {
      navigate(`/DetailsAdd?id=${service.service_id}`);
    } else {
      console.error("Service ID is missing");
    }
  };

  const getDirection = () => {
    return i18n.language === "ar" ? "rtl" : "ltr";
  };

  const toggleDropdownVisibility = () => {
    setIsDropdownVisible(false);
  };

  return (
    <div className="treatment-container12" style={{ direction: getDirection() }}>
      <div className="headersection12">
        <div className='headersection121'>
          <p className='talib112'> 
            {t("Gorfe.Headsoltitle2")}
            {t("Gorfe.Headsoltitle3")}
            <br />
            {t("Slideshow.title1")}
          </p>
        </div>
      </div>

      <div className="search-bar-wrapper">
        <div className='seckrow'>
          <div className="new-input-container">
            <div className="input-group"> 
              <input
                type="text"
                placeholder={t("Gorfe.search_placeholder")}
                value={searchQuery}
                onChange={handleSearchChange}
                className="search-input professional-input"
                aria-label={t("Gorfe.search_placeholder")}
              />
            </div>
          </div>
        </div>

        {searchQuery && isDropdownVisible && filteredServices1.length > 0 && (
          <div className="dropdown-results" ref={dropdownRef}>

            {filteredServices1.map(service => (
              <div
                key={service.service_id}
                className="dropdown-item"
                onClick={() => handleServiceClick(service)}
              >
                <div className="item-content">
                  <img src={service.image} alt={service.title} className="service-image" />
                  <span className="service-title">{service.title}</span>
                </div>
              </div>
            ))}
          </div>
        )}

        {searchQuery && filteredServices1.length === 0 && (
          <p className="no-results-message">{t("services.no_services_found")}</p>
        )}
      </div>
    </div>
  );
};

export default Borde;

import React, { useEffect } from 'react';
import Pill from "../Pill";
import './EstablishmentServicesAssistance.css';

const EstablishmentServicesAssistance = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);



  return (
    <div className="establishment-services-assistance-container">

      <div className="restricted-page"> 
        <h1 className="title">شبكة لها و لك الطبية</h1>
        <p className="message">انتظرونا قريبا وبامكانكم انهاء الاجراءات عبر زيارة مكاتب خدمة العملاء او التواصل معنا</p>
        <p className="contact-info">
          <strong>أرقام التواصل:</strong> 0571605516 - 0599489990
        </p>
        <p className="email-info">
          <strong>Email:</strong> info@lahalak.sa
        </p>
        <p className="address">
          <strong>جده:</strong> شارع فلسطين 6286، حي الحمراء، عمارة نورا 3738<br />
          <strong>الرياض:</strong> شارع الملك فيصل 15366، المربع، قاعه 4
        </p>
        <br/>
        <br/>
        <div className='centre'><Pill /></div>
      </div>
    </div>
  );
};

export default EstablishmentServicesAssistance;

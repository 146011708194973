
import Slider from "react-slick";
import { useTranslation } from 'react-i18next';
import './Footersection.css';
import { Link } from "react-router-dom";
import { FaTachometerAlt, FaMedal, FaEnvelopeOpenText, FaStethoscope, FaUserFriends } from 'react-icons/fa';
import React, { useState, useEffect, useRef } from 'react';

const useCountUp = (from, to, isVisible, duration = 6000) => {
    const [count, setCount] = useState(from);

    useEffect(() => {
        if (!isVisible) return;  

        let start = from;
        const increment = (to - from) / (duration / 100);
        const interval = setInterval(() => {
            start += increment;
            if (start >= to) {
                setCount(to);
                clearInterval(interval);
            } else {
                setCount(Math.ceil(start));
            }
        }, 100);

        return () => clearInterval(interval);
    }, [isVisible, from, to, duration]);

    return count;
};

const Footersection = () => {
    const { t, i18n } = useTranslation("global");
    const isRTL = i18n.language === "ar";
    const getDirection = () => {
        return i18n.language === "ar" ? "rtl" : "ltr";
    };

    const [isVisible, setIsVisible] = useState(false);
    const counterRef = useRef(null);
    const partners = [


        {
            nameKey: t("Footersection.name5"), // Care For You - Jeddah
            imgSrc: "https://firebasestorage.googleapis.com/v0/b/lahaalakaa.appspot.com/o/Care4Y.png?alt=media&token=d67cb04f-6971-4975-b2c1-ecf15edc4dc9",
            locationKey: t("Footersection.location5") // Jeddah
        },
        {
            nameKey: t("Footersection.name7"), // Saudi German Hospital - University
            imgSrc: "https://firebasestorage.googleapis.com/v0/b/lahaalakaa.appspot.com/o/y0GW3Z0K_400x400%20(1).jpg?alt=media&token=493c476c-07ee-4c7f-8d47-ae8ada150713",
            locationKey: t("Footersection.location2") // University
        },
        {
            nameKey: t("Footersection.name9"), // Andalusia Hospital - Jeddah
            imgSrc: "https://firebasestorage.googleapis.com/v0/b/lahaalakaa.appspot.com/o/andalusia.png?alt=media&token=3ad14da9-a5ef-41b2-81d7-aef6ca177081",
            locationKey: t("Footersection.location9") // Jeddah
        },
        {
            nameKey: t("Footersection.name10"), // Dr. Irfan General Hospital - Jeddah
            imgSrc: "https://firebasestorage.googleapis.com/v0/b/lahaalakaa.appspot.com/o/passport_fc84a337-3840-4dd4-bacf-671c391447c6.png?alt=media&token=4ebfc2aa-3852-446b-9fee-743bc5c2b798",
            locationKey: t("Footersection.location10") // Jeddah
        },
        {
            nameKey: t("Footersection.name8"), // Saudi German Hospital - Riyadh - Al Fayhaa
            imgSrc: "https://firebasestorage.googleapis.com/v0/b/lahaalakaa.appspot.com/o/y0GW3Z0K_400x400%20(1).jpg?alt=media&token=493c476c-07ee-4c7f-8d47-ae8ada150713",
            locationKey: t("Footersection.location8") // Riyadh - Al Fayhaa
        },
        {
            nameKey: t("Footersection.name11"), // Alia Al Farid Medical Complex
            imgSrc: "https://firebasestorage.googleapis.com/v0/b/lahaalakaa.appspot.com/o/Alia_Al_Farid.png?alt=media&token=52157d0e-bf5e-48d8-a91e-9516e9e3605e",
            locationKey: t("Footersection.location11") // Jeddah
        },
        {
            nameKey: t("Footersection.name6"), // Sakinah for Psychological and Family Counseling
            imgSrc: "https://firebasestorage.googleapis.com/v0/b/lahaalakaa.appspot.com/o/sakinia.png?alt=media&token=22935df8-dd34-4084-8df3-39a649b67671",
            locationKey: t("Footersection.location6") // Jeddah
        },
        {
            nameKey: t("Footersection.name12"), // Saudi German Hospital - Mecca
            imgSrc: "https://firebasestorage.googleapis.com/v0/b/lahaalakaa.appspot.com/o/y0GW3Z0K_400x400%20(1).jpg?alt=media&token=493c476c-07ee-4c7f-8d47-ae8ada150713",
            locationKey: t("Footersection.location12") // Mecca
        },
        {
            nameKey: t("Footersection.name13"), // My Clinic - Jeddah
            imgSrc: "https://firebasestorage.googleapis.com/v0/b/lahaalakaa.appspot.com/o/MY_Clinic.png?alt=media&token=11d46c4c-ac51-4edc-ab56-bb177e1edc3e",
            locationKey: t("Footersection.location13") // Jeddah
        },
        {
            nameKey: t("Footersection.name14"), // A Specialized Hospital
            imgSrc: "https://firebasestorage.googleapis.com/v0/b/lahaalakaa.appspot.com/o/Aya.png?alt=media&token=980cd7f3-c37e-42ea-adfb-06b01fd30cc8",
            locationKey: t("Footersection.location14") // Jeddah
        },
        {
            nameKey: t("Footersection.name15"),
            imgSrc: "https://firebasestorage.googleapis.com/v0/b/lahaalakaa.appspot.com/o/wid.png?alt=media&token=255e6a62-9956-40a9-9289-5169a2439c7e",
            locationKey: t("Footersection.location15")
        },
        {
            nameKey: t("Footersection.name16"),
            imgSrc: "https://firebasestorage.googleapis.com/v0/b/lahaalakaa.appspot.com/o/y0GW3Z0K_400x400%20(1).jpg?alt=media&token=493c476c-07ee-4c7f-8d47-ae8ada150713",
            locationKey: t("Footersection.location16")
        },
        {
            nameKey: t("Footersection.name17"),
            imgSrc: "https://firebasestorage.googleapis.com/v0/b/lahaalakaa.appspot.com/o/midlab.png?alt=media&token=df20ea10-b449-4e16-ba93-307824043bf9",
            locationKey: t("Footersection.location17")
        },
        {
            nameKey: t("Footersection.name18"),
            imgSrc: "https://firebasestorage.googleapis.com/v0/b/lahaalakaa.appspot.com/o/Award_al_beshri_hospital.jpg?alt=media&token=42ac8117-9515-44bb-ae9f-54afb4065143",
            locationKey: t("Footersection.location18")
        },
        {
            nameKey: t("Footersection.name13"), // My Clinic - Jeddah
            imgSrc: "https://firebasestorage.googleapis.com/v0/b/lahaalakaa.appspot.com/o/MY_Clinic.png?alt=media&token=11d46c4c-ac51-4edc-ab56-bb177e1edc3e",
            locationKey: t("Footersection.location44") // Jeddah
        },
        {
            nameKey: t("Footersection.name19"),
            imgSrc: "https://firebasestorage.googleapis.com/v0/b/lahaalakaa.appspot.com/o/ACL.png?alt=media&token=00d3067d-dd4e-4ba9-a936-622228101960",
            locationKey: t("Footersection.location19")
        },
        {
            nameKey: t("Footersection.name20"),
            imgSrc: "https://firebasestorage.googleapis.com/v0/b/lahaalakaa.appspot.com/o/BADGHISH-logo-ar-1.png?alt=media&token=0da7928d-0429-4c3f-833d-ab20a4852038",
            locationKey: t("Footersection.location20")
        },
        {
            nameKey: t("Footersection.name21"),
            imgSrc: "https://firebasestorage.googleapis.com/v0/b/lahaalakaa.appspot.com/o/Pioneer.png?alt=media&token=e7fcf6b2-14b9-436c-93e4-cd2c5e9f11b9",
            locationKey: t("Footersection.location21")
        }
    ];

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting) {
                    setIsVisible(true);
                    observer.unobserve(counterRef.current);  // Unobserve after it becomes visible
                }
            },
            { threshold: 0.1 }  
            // Trigger when 10% of the element is visible
        );

        if (counterRef.current) {
            observer.observe(counterRef.current);
        }

        return () => {
            if (counterRef.current) {
                observer.unobserve(counterRef.current);
            }
        };
    }, []);

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        rtl: isRTL,
        centerMode: true,
        centerPadding: "0px",
        arrows: false,
      
        responsive: [
          {
            breakpoint: 1024, // Tablet
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              centerMode: false,
            }
          },
          {
            breakpoint: 768, // Mobile
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            }
          }
        ]
      };
      

    return (
        <div className="cleopatra1" style={{ direction: getDirection() }}>
            <div className="cleopatra2">
                <p>{t("Footersection.Our_partner")}</p>
            </div>
            <div className='cleopaetra10'>
                <Link to={'/Our_Partner'}>
                    <button className='buttonclerop'>
                        {isRTL ? (
                            <>
                                {t("Footersection.more")} <span style={{ color: 'green' }}>◄</span>
                            </>
                        ) : (
                            <>
                                {t("Footersection.more")} <span style={{ color: 'green' }}>►</span>
                            </>
                        )}
                    </button>
                </Link>
            </div>
            <div className='cleopatra4'>
                <Slider {...settings}>
                    {partners.map((partner, index) => (
                        <div key={index} className="partner-item">
                            <div className='cleopatra3'><img src={partner.imgSrc} alt={t(partner.nameKey)} /></div>
                            <p>{t(partner.nameKey)}</p>
                            <p>{t(partner.locationKey)}</p>
                        </div>
                    ))}
                </Slider>
            </div>
            <div className='baydaS'>
                <br />
                <p className='GoldContabasita centre'>
                {t("Footersection.Our_achievements")}
                </p>
                <br />
                <br />
            </div>
            <div className="Chloe-container lg:col-span-3 flex justify-around items-center mb-8" ref={counterRef}>
                <div className="Chloe1 text-center">
                    <FaTachometerAlt className="Chloe-icon mb-2" /> 
                    <p className="Chloe-text">{useCountUp(0, 91, isVisible)}%</p>
                    <p className="Chloe-subtext">{t("footer.responseSpeedLabel")}</p>
                </div>
                <div className="Chloe2 text-center">
                    <FaMedal className="Chloe-icon mb-2" /> 
                    <p className="Chloe-text">{useCountUp(0, 98, isVisible)}%</p>
                    <p className="Chloe-subtext">{t("footer.medicalCenterSatisfactionLabel")}</p>
                </div>
                <div className="Chloe3 text-center">
                    <FaEnvelopeOpenText className="Chloe-icon mb-2" />
                    <p className="Chloe-text">{useCountUp(0, 1000000, isVisible)}</p>
                    <p className="Chloe-subtext">{t("footer.customerInquiriesAnsweredLabel")}</p>
                </div>
                <div className="Chloe4 text-center">
                    <FaStethoscope className="Chloe-icon mb-2" />  
                    <p className="Chloe-text">{useCountUp(0, 6200, isVisible)}</p>
                    <p className="Chloe-subtext">{t("footer.successfulMedicalExaminationsLabel")}</p>
                </div>
                <div className="Chloe5 text-center">
                    <FaUserFriends className="Chloe-icon mb-2" />
                    <p className="Chloe-text">{useCountUp(0, 11000, isVisible)}</p>
                    <p className="Chloe-subtext">{t("footer.customersLabel")}</p>
                </div>
            </div>
        </div>
    );
};

export default Footersection;
